import { GET_ADS, GET_MORE_ADS, HIDE_ADS_BUTTON } from '../actions/ads-action';

const initialState = {
    ads: [],
    adsTableData: [],
    lastPage: false,
}

export default function job(state = initialState, action) {
    switch (action.type) {
        case GET_ADS:
            return {
                ...state,
                ads: action.payload,
                adsTableData: action.payload.tableData,
                lastPage: action.showLoadMore
            }
        case GET_MORE_ADS:
            return {
                ...state,
                ads: action.payload,
                adsTableData: [...state.adsTableData, ...action.payload.tableData],
                lastPage: action.showLoadMore
            }
        case HIDE_ADS_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}