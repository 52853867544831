import { GET_RIDES, GET_RIDES_DETAILS, HIDE_RIDES_BUTTON, GET_MORE_RIDES,GET_POST_MASTER } from '../actions/ride-action';

const initialState = {
    rides: {},
    ridesTableData: [],
    rideDetails: {},
    postMaster: {},
    morePosts: [],
    lastPage: false,
}

export default function ride(state = initialState, action) {
    switch (action.type) {
        case GET_RIDES:
            return {
                ...state,
                rides: action.payload,
                ridesTableData: action.payload.tableData,
                // postMaster: action.postMaster,
                lastPage: action.showLoadMore
            }
        case GET_MORE_RIDES:
            return {
                ...state,
                rides: action.payload,
                ridesTableData: [...state.ridesTableData, ...action.payload.tableData],
                lastPage: action.showLoadMore
            }
        case GET_RIDES_DETAILS:
            return {
                ...state,
                rideDetails: { ...state.rideDetails, ...action.payload.posts },
                morePosts: action.payload.morePosts
            }
        case GET_POST_MASTER:
            return {
                ...state,
                postMaster: action.payload,
            }
        case HIDE_RIDES_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}