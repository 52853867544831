import guidelinesImg from '../../assets/images/guideLines.jpg';
import responseToCovid from '../../assets/images/ResponseToCovid.png';
import healthNSafety from '../../assets/images/HealthNSafety.png';
import doorToDoorSafety from '../../assets/images/DoorToDoorSafety.png';
import './GuideLines.css';

function GuideLinesPage(props) {
    return (
        <div className="mb-32">
            <div className="relative">
                <img className="object-cover w-full max-h-425" alt="Press" src={guidelinesImg} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35 w-3/5 sm:w-full">OUR RESPONSE TO COVID-19</h1>
                    <p className="text-12 sm:text-17 hidden sm:block">Steps Everyone Can Ter</p>
                </div>
            </div>

            <div className="px-5 lg:px-24 container mt-8 lg:mt-44">
                <div className="topSvg absolute hidden lg:block">
                    <svg width="124" height="100" viewBox="0 0 124 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.4" cx="87.3435" cy="66.4447" r="32.9942" transform="rotate(-155.241 87.3435 66.4447)" fill="url(#paint0_radial_102_2262)" />
                        <circle opacity="0.4" cx="20.3411" cy="25.1486" r="19.9312" transform="rotate(-155.241 20.3411 25.1486)" fill="url(#paint1_radial_102_2262)" />
                        <circle opacity="0.3" cx="112.991" cy="10.8356" r="10.7868" transform="rotate(-155.241 112.991 10.8356)" fill="url(#paint2_radial_102_2262)" />
                        <defs>
                            <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(87.3435 33.4505) rotate(90) scale(65.9883)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.3411 5.21738) rotate(90) scale(39.8624)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(112.991 0.0488787) rotate(90) scale(21.5735)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div className="flex mt-8 lg:mt-36 lg:space-x-6 flex-col-reverse lg:flex-row">
                    <div className="lg:w-1/2 pr-10 mt-14 lg:mt-0">
                        <h1 className="font-bold text-20 sm:text-35">OUR RESPONSE TO COVID-19</h1>
                        <p className="text-14 sm:text-20 mt-8 text-justify">Maintaining physical distance to prevent COVID-19 is often difficult on public transportation. People may not be able to keep a distance of 6 feet from others on airplanes, trains, or buses.<br /> <br />

                        Protect yourself from COVID-19 by staying up to date with your COVID-19 vaccines and by wearing a mask on public transportation. Wearing a mask over your nose and mouth is required in indoor areas of planes, buses, trains, and other forms of public transportation traveling into, within, or out of the United States, and while indoors at U.S. transportation hubs (such as airports and stations), regardless of your vaccination status.</p>
                    </div>
                    <div className="lg:w-1/2 flex items-center">
                        <div className="lg:p-10 h-full w-full">
                            <div className='relative'>
                                <div className="absolute twoGridImage hidden lg:block">
                                    <svg width="560" height="468" viewBox="0 0 560 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.2" cx="175.447" cy="175.371" r="174.926" fill="url(#paint0_radial_102_2262)" />
                                        <circle opacity="0.2" cx="447.505" cy="356.321" r="111.542" fill="url(#paint1_radial_102_2262)" />
                                        <defs>
                                            <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.447 0.444702) rotate(90) scale(349.853)">
                                                <stop stopColor="#FFE6B3" />
                                                <stop offset="0.442708" stopColor="#FFD171" />
                                                <stop offset="1" stopColor="#F7AC49" />
                                            </radialGradient>
                                            <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.505 244.779) rotate(90) scale(223.084)">
                                                <stop stopColor="#FFE6B3" />
                                                <stop offset="0.442708" stopColor="#FFD171" />
                                                <stop offset="1" stopColor="#F7AC49" />
                                            </radialGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <img className="rounded-md lg:rounded-38 w-full h-full object-cover" src={responseToCovid} alt="Covid Guide Lines" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-14 lg:mt-24">
                    <h1 className='font-bold text-20 sm:text-35'>All travelers should take steps to protect themselves and others.</h1>
                    <div className="sm:space-x-6 mt-5 sm:mt-8">
                        <p className="inline-block underline cursor-pointer text-17 sm:text-20">Follow CDC guideline</p>
                        <p className="inline-block underline cursor-pointer text-17 sm:text-20 mt-4 sm:mt-0">Safely on travel</p>
                        <p className="inline-block underline cursor-pointer text-17 sm:text-20 mt-4 sm:mt-0">Sanitize yourself</p>
                        <p className="inline-block underline cursor-pointer text-17 sm:text-20 mt-4 sm:mt-0">Handwash & Wearing Mask</p>
                    </div>
                </div>

                <div className="block sm:flex justify-between sm:space-x-40 mt-20 relative">
                    {/* <div className="absolute hidden lg:block centerSvg">
                    <svg width="69" height="415" viewBox="0 0 69 415" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="-94.9184" cy="272.995" r="141.644" transform="rotate(-1.03198 -94.9184 272.995)" fill="url(#paint0_radial_102_2262)" />
                        <circle opacity="0.4" cx="23.6096" cy="45.8162" r="45.1255" transform="rotate(-1.03198 23.6096 45.8162)" fill="url(#paint1_radial_102_2262)" />
                        <circle opacity="0.5" cx="25.1177" cy="129.557" r="14.7529" transform="rotate(-1.03198 25.1177 129.557)" fill="url(#paint2_radial_102_2262)" />
                        <defs>
                            <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-94.9184 131.351) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.6096 0.69075) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.1177 114.805) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div> */}
                    <div className="w-full sm:w-5/6">
                        <div className='relative'>
                            <img className="rounded-md lg:rounded-38 w-full h-full object-cover" src={healthNSafety} alt="Covid Guide Lines" />
                            <div className="absolute singleGridImage hidden lg:block">
                                <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="111.678" cy="112.296" r="111.542" fill="url(#paint0_radial_259_4269)" />
                                    <defs>
                                        <radialGradient id="paint0_radial_259_4269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.678 0.753845) rotate(90) scale(223.084)">
                                            <stop stopColor="#FFE6B3" />
                                            <stop offset="0.442708" stopColor="#FFD171" />
                                            <stop offset="1" stopColor="#F7AC49" />
                                        </radialGradient>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="px-3">
                            <h2 className="font-bold text-20 sm:text-27 mt-14 sm:mt-16">Self-Testing at Home</h2>
                            <p className="text-14 sm:text-20 mt-5 sm:mt-10 text-justify">Before leaving your home , secure your health and others too, test yourself using a self testing kit at home. Follow cdc guideline it is being frequently updated according situation, </p>
                            <ul className="font-medium text-14 sm:text-20 mt-5 sm:mt-8 list-decimal ml-5">
                                <li>Lets contribute to end covid-19 pandemic</li>
                                <li>If you develop symptoms Isolate yourself</li>
                                <li>Immediately and get tested.</li>
                                <li>Advice to not travel</li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full sm:w-5/6 mt-14 sm:mt-0'>
                        <div className="relative">
                            <img className="rounded-md lg:rounded-38 w-full h-full object-cover" src={doorToDoorSafety} alt="Covid Guide Lines" />
                            <div className="absolute singleGridImage hidden lg:block">
                                <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.2" cx="111.678" cy="112.296" r="111.542" fill="url(#paint0_radial_259_4269)" />
                                    <defs>
                                        <radialGradient id="paint0_radial_259_4269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.678 0.753845) rotate(90) scale(223.084)">
                                            <stop stopColor="#FFE6B3" />
                                            <stop offset="0.442708" stopColor="#FFD171" />
                                            <stop offset="1" stopColor="#F7AC49" />
                                        </radialGradient>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="px-3">
                            <h2 className="font-bold text-20 sm:text-27 mt-14 sm:mt-16">Your health and safety</h2>
                            {/* <p className="text-14 sm:text-20 mt-5 sm:mt-10 text-justify">Charles tosser quaint twit bamboozled burke brown bread I naff I don't want no agro say blimey.!! You mug dropped a clanger barmy David brown bread bleeding crikey say chimney pot me old mucker bugger super.</p> */}
                            <ul className="font-medium text-14 sm:text-20 mt-5 sm:mt-8 list-decimal ml-5">
                                <li>Make sure before travel no symptoms</li>
                                <li>Always get update of destination covid -19 guidelines</li>
                                <li>Wearing mark during journey</li>
                                <li>Follow the CDC guideline </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideLinesPage;