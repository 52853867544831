import { GET_FAQS } from '../actions/contactus-action';

const initialState = {
    faqs: {}
}

export default function contactus(state = initialState, action) {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        default:
            return state;
    }
}