import qs from 'qs';
import axios from 'axios';
import { SPONSOR_ADS } from '../../constants';

export const GET_ADS = 'GET_ADS';
export const HIDE_ADS_BUTTON = 'HIDE_ADS_BUTTON';
export const GET_MORE_ADS = 'GET_MORE_ADS';

export const getAdsData = pageNo => async dispatch => {
    try {
        const response = await getAdsByRequestData(pageNo);

        dispatch({
            type: GET_ADS,
            payload: response.data.result.responce.Sponsoradds,
            showLoadMore: false
        })
        if (response?.data?.result?.responce?.Sponsoradds?.tableData?.length === 0) {
            dispatch({
                type: HIDE_ADS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getMoreAds = pageNo => async dispatch => {
    try {
        const response = await getAdsByRequestData(pageNo);
        if (response?.data?.result?.responce?.Sponsoradds?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_ADS,
                payload: response.data.result.responce.Sponsoradds,
                showLoadMore: false
            })
        } else {
            dispatch({
                type: HIDE_ADS_BUTTON,
                payload: true
            })
        }
    } catch (error) {

    }
}

const getAdsByRequestData = pageNo => {
    return axios({
        method: 'post',
        url: SPONSOR_ADS,
        data: qs.stringify({
            start: pageNo
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}