import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import SaleCard from '../../components/Sale/Sale-Card';
import MobileAppAd from '../../components/AppDetails/MobileApp-Ad';
import SocialIconsDark from '../../components/Social-Dark'
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getRidesDetails } from '../../store/actions/ride-action'
import moment from 'moment';
import './Rides.css';
import SocialIconsDarkShare from '../../components/Social-Dark-share';
function RideDetailsPage(props) {
    let history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRidesDetails(id))
    }, [dispatch, id]);

    const rideDetails = useSelector((state) => {
        return state.rides.rideDetails;
    });

    const morePosts = useSelector((state) => {
        return state.rides.morePosts;
    });

    function saleClick(item) {
        history.push(`/rides/${item.id}`);
    }

    function getLuggageCount(obj) {
        if(obj) {
            return obj.l + obj.m + obj.s;
        }else {
            return 0;
        }
    }

    return (
        <React.Fragment>
        {rideDetails && <div className="md:container mx-auto md:px-10">
            <div className="mt-6 sm:mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                <p><Link to="/">Home</Link> &gt; <Link to="/rides">Rides</Link> &gt; {rideDetails.address_full}</p>
            </div>
            <div className="mt-6 sm:mt-9">
                <Carousel
                    autoPlay={false}
                    infiniteLoop={true}
                    swipeable={true}
                    dynamicHeight={true}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={false}>
                    {rideDetails?.documents && rideDetails.documents.map((item, i) => (<img key={i} className="w-full md:rounded max-h-425" alt="Selected Car" src={item.display_files.post_image.main_url} />))}
                </Carousel>
                <div className="flex">
                    <div className="md:w-2/3 px-5 md:px-0">
                        <div className="mt-0 sm:mt-8">
                            <h1 className="font-bold text-20 sm:text-31">{rideDetails.address_full}</h1>
                            <div className="flex items-center space-x-4 mt-4 justify-between sm:justify-start">
                                <RAMARUM_BUTTON
                                    className="m-auto"
                                    text={(rideDetails.post_type === 'RS' && <span>Wanted</span>) || (rideDetails.post_type === 'RP' && <span>Offering</span>)}
                                    btnType={(rideDetails.post_type === 'RS' && 'full') || (rideDetails.post_type === 'RP' && 'outline')}
                                    size="sm" />
                                <p>Posted {moment(rideDetails?.created).fromNow()}</p>
                            </div>
                            <p className="font-bold text-23 sm:text-35 mt-5">${rideDetails?.your_amount && rideDetails.your_amount.toFixed(2)}</p>
                            <div className="mt-6">
                                <p className="font-medium text-18 sm:text-21">Description:</p>
                                {rideDetails?.json_data && <ul className="text-18 sm:text-21 mt-4">
                                    {rideDetails?.json_data[rideDetails?.post_type].smoke === '0' && <li className="mt-3">Non-Smoking</li>}
                                    {rideDetails?.json_data[rideDetails?.post_type].pet === '1' && <li className="mt-3">Pet Friendly</li>}
                                    {rideDetails?.json_data[rideDetails?.post_type].wheelchair === '1' && <li className="mt-3">Wheel Chair</li>}
                                    <li className="mt-3">{getLuggageCount(rideDetails?.json_data[rideDetails?.post_type].luggage)} Luggage Space</li>
                                </ul>}
                            </div>
                            <p className="text-18 sm:text-28 font-semibold mt-7">Ready To Ride ? <span className="text-darkYellow">Download Our App Today.</span></p>
                            <div className="font-medium text-25 mt-11">
                                <SocialIconsDarkShare title="Share This Ride On" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block mt-n-10">
                        <MobileAppAd />
                    </div>
                </div>
            </div>
            <div className='px-5 md:px-0'>
                <h2 className="font-bold text-35 mt-20">More Rides</h2>
                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 my-11">
                    {morePosts && morePosts.map((item, i) => (
                        <div key={i}>
                            <SaleCard
                                displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                text={item.address_full}
                                amount={item.your_amount}
                                btnType={(item.post_type === 'RS' && 'full') || (item.post_type === 'RP' && 'outline')}
                                btnText={(item.post_type === 'RS' && <span>Wanted</span>) || (item.post_type === 'RP' && <span>Offering</span>)}
                                timestamp={item?.created}
                                saleClick={() => saleClick(item)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>}
        </React.Fragment>
    );
};

export default RideDetailsPage;