import MOBILE_DOWNLOAD_LINK from "./Download-Links";
// import appGroup from '../assets/images/mobile/appGroup.svg';
import appGroup from '../assets/images/mobile/appGroup.png';

function DownLoadOurApp(props) {
    return (
        <div className="flex flex-col-reverse lg:flex-row px-6 lg:px-0">
            <div className="lg:w-1/2 lg:mr-8">
            <img  alt="Mobile" src={appGroup} />
                {/* <div className="flex mb-n-22p">
                    <img className="object-cover h-full w-56 zidx-n-1" alt="Mobile" src={appGroup} />
                </div> */}
            </div>
            <div className="lg:w-1/2 text-center md:text-left mb-8 pt-16">
                <h1 className="hw-title font-bold">Download Our App Now</h1>
                <div className="flex space-x-5 hw-mt-user pb-5 justify-center lg:justify-start">
                    <MOBILE_DOWNLOAD_LINK image="WHITE" />
                </div>
            </div>

        </div>
    );
};

export default DownLoadOurApp;