import React, { } from 'react';
import ellipseGray from '../assets/images/mobile/ellipseGray.svg';


function BenefitApp(props) {

    function BenefitsData(props) {
        return (
            <div>
                <img className="block mx-auto" alt="ellipse" src={ellipseGray} />
                <p className="font-bold text-16 sm:text-24 text-center mt-4">Before you’re out</p>
                <p className="font-medium text-10 sm:text-18 text-center mt-4">Access exclusive perks for the things you love and places you go.</p>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="text-center self-center mt-20">
                <h1 className="text-20 sm:text-28 font-bold">Benefits of using Our App</h1>
                <p className="font-medium text-12 sm:text-18 mt-5">Access exclusive perks for the things you love and places you go.</p>
            </div>
            <div className="flex justify-center">
                <div className="flex space-x-10 mt-11 justify-center lg:justify-center w-1/2">
                    <BenefitsData></BenefitsData>
                    <BenefitsData></BenefitsData>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="flex space-x-10 mt-11 justify-center lg:justify-center w-4/6">
                    <BenefitsData></BenefitsData>
                    <BenefitsData></BenefitsData>
                    <BenefitsData></BenefitsData>
                </div>
            </div>

        </React.Fragment>
    );
}
export default BenefitApp