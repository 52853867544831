
function RefundPolicyPage(props) {
    return (
        <div className="px-5 lg:px-24 mt-7 sm:mt-16 mb-32">
            {/* <h1 className="font-semibold sm:font-bold text-20 sm:text-35">Refund Policy</h1> */}
            {/* <ol className="mt-7 sm:mt-11 list-decimal text-12 sm:text-20 px-4">
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li className="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
            </ol> */}
             {/* <div className="mt-7 sm:mt-11 list-decimal text-12 sm:text-20 px-4" dangerouslySetInnerHTML={{ __html: ` */}
             <div className="mt-7 sm:mt-11 px-4">
               <h1 className="font-bold text-25 sm:text-35">Return and Refund Policy</h1>
               <p  className="opacity-50 text-14 sm:text-20 font-medium">Last updated: February 24, 2022</p>
               <p className="text-14 sm:text-20 text-justify mt-8">Thank you for shopping at Ramarum Mobile App website and Ramarum app.</p>
               <p className="text-14 sm:text-20 text-justify">If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-return-refund-policy-generator/" target="_blank">Return and Refund Policy Generator</a>.</p>
               <p className="text-14 sm:text-20 text-justify">The following terms are applicable for any products that You purchased with Us.</p>
               <h1 className="font-bold text-18 sm:text-25 mt-8">Interpretation and Definitions</h1>
               <h2 className="font-bold text-14 sm:text-22 mt-8">Interpretation</h2>
               <p className="text-14 sm:text-20 text-justify mt-2">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
               <h2 className="font-bold text-14 sm:text-22 mt-8">Definitions</h2>
               <p className="text-14 sm:text-20 text-justify">For the purposes of this Return and Refund Policy:</p>
               <ul className="list-disc text-14 sm:text-18 mt-8 ml-4">
               <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Ramarum
               </li>
               <li className="mt-2"><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Ramarum LLC, 3 Main St, Summerton, SC 29148, United States.
               </li>
               <li className="mt-2"><strong>Goods</strong> refer to the items offered for sale on the Service.
               </li>
               <li className="mt-2"><strong>Orders</strong> mean a request by You to purchase Goods from Us.
               </li>
               <li className="mt-2"><strong>Service</strong> refers to the Application or the Website or both.
               </li>
               <li className="mt-2"><strong>Website</strong> refers to Ramarum Mobile App, accessible from <a href="https://ramarum.com/" rel="external nofollow noopener" target="_blank">https://ramarum.com/</a>
               </li>
               <li className="mt-2"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
               </li>
               </ul>
               <h1 className="font-bold text-18 sm:text-25 mt-8">Your Order Cancellation Rights</h1>
               <p className="text-14 sm:text-20 text-justify">You are entitled to cancel Your Order within 14 days without giving any reason for doing so.</p>
               <p className="text-14 sm:text-20 text-justify">The deadline for canceling an Order is 2 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
               <p className="text-14 sm:text-20 text-justify">In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
               <ul className="text-14 sm:text-18">
               <li>By email: support@ramarum.com</li>
               </ul>
               <p className="text-14 sm:text-20 text-justify">We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
               <h1 className="font-bold text-18 sm:text-25 mt-8">Conditions for Returns</h1>
               <p className="text-14 sm:text-20 text-justify">In order for the Goods to be eligible for a return, please make sure that:</p>
               <ul className="text-14 sm:text-18">
               <li>The Goods were purchased in the last 14 days</li>
               </ul>
               <p className="text-14 sm:text-20 text-justify">The following Goods cannot be returned:</p>
               <ul className="text-14 sm:text-18">
               <li>The supply of Goods made to Your specifications or clearly personalized.</li>
               <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
               <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
               <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
               </ul>
               <p className="text-14 sm:text-20 text-justify">We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
               <p className="text-14 sm:text-20 text-justify">Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
               <h1 className="font-bold text-18 sm:text-25 mt-8">Returning Goods</h1>
               <p className="text-14 sm:text-20 text-justify mt-2">You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
               <p className="text-14 sm:text-20 text-justify">there is not any physicals address to send us</p>
               <p className="text-14 sm:text-20 text-justify">We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
               <h1 className="font-bold text-18 sm:text-25 mt-8">Gifts</h1>
               <p className="text-14 sm:text-20 text-justify mt-2">If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
               <p className="text-14 sm:text-20 text-justify">If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
               <h2 className="font-bold text-14 sm:text-22 mt-8">Contact Us</h2>
               <p className="text-14 sm:text-20 text-justify mt-2">If you have any questions about our Returns and Refunds Policy, please contact us:</p>
               <ul className="text-14 sm:text-18 mt-2">
               <li>By email: support@ramarum.com</li>
               </ul>               
               {/* ` }}></div> */}
               </div>
        </div>
    );
};

export default RefundPolicyPage;