import { combineReducers } from 'redux';
import blogReducer from './blog-reducer';
import contactusReducer from './contactus-reducer';
import pressReducer from './press-reducer';
import rideReducer from './ride-reducer';
import jobReducer from './job-reducer';
import homedata from './home-reducer';
import sponserReducer from './sponser-reducer';
import investorReducer from './investor-reducer';
import adsReducer from './ads-reducer';
import howitswork from './howitswork-reducer';

export default combineReducers({
    home: homedata,
    blogs: blogReducer,
    contactus: contactusReducer,
    press: pressReducer,
    rides: rideReducer,
    jobs: jobReducer,
    sponsers: sponserReducer,
    investors: investorReducer,
    ads: adsReducer,
    howwork:howitswork
});