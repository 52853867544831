import MOBILE_DOWNLOAD_LINK from './Download-Links';
// import mgroup from '../assets/images/mobile/mgroup.svg';
// import ride from '../assets/images/ride/ride.svg';
import mgroup from '../assets/images/mobile/mgroup.png';
import ride from '../assets/images/ride/ride.png';
import ride1 from '../assets/images/ride/ride1.png';
import ride2 from '../assets/images/ride/ride2.png';
import ride3 from '../assets/images/ride/ride3.png';
import ride4 from '../assets/images/ride/ride4.png';
import ride5 from '../assets/images/ride/ride5.png';
import job from '../assets/images/job/job.svg';
import job1 from '../assets/images/job/job1.png';
import job2 from '../assets/images/job/job2.png';
import job3 from '../assets/images/job/job3.png';
import job4 from '../assets/images/job/job4.png';
import job5 from '../assets/images/job/job5.png';
import React, { useCallback, useEffect, useState } from 'react';
import DownLoadOurApp from './DownLoadOurApp';
import BenefitApp from './BenefitApp';
import AdvertiseWithUs from './AdvertiseWithUs';
import { getStatastics } from '../store/actions/howitswork-action';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';



function BestRidesJobs(props) {
    const [segment, setSegment] = useState('RIDE');
    const dispatch = useDispatch();

    const getDataStatistics = useCallback(
        _.debounce(data => {
            dispatch(getStatastics())
        }, 1000),
        []
    );
    useEffect(() => {
        getDataStatistics();
    }, [dispatch]);

    const statasticsData = useSelector((state) => {
        console.log("statasticsData...useSelector", state.howwork.statastics);
        return state.howwork.statastics;
    });

    function segmentChange(seg) {
        setSegment(seg);
    }

    const twoGridContentRideJob = (item) => {
        return <div className="flex lg:space-x-6 flex-col lg:flex-row side-px">
            <div className="lg:w-1/2 pr-10 mb-5 lg:mb-0 lg:mt-0  lg:self-center mt-5">
                <h2 className="font-bold lg:text-45 sm:text-40">{item.title}</h2>
                {/* <p className="text-14 sm:text-20 mt-4 sm:mt-8 text-justify" >{item.discription}</p> */}
                <p className="text-14 font-medium sm:text-20 mt-4" dangerouslySetInnerHTML={{ __html: item.discription }}></p>
            </div>
            <div className="lg:w-1/2 flex items-center">
            {/* className="h-full w-full relative" */}
                <div style={{margin:'0 auto'}}>
                    {/* <img className='rounded-md lg:rounded-38 bg-cover h-full w-full mx-auto object-cover' src={item.image} alt="About Us" /> */}
                    <img className="h-ridejob" src={item.image} alt="About Us" />

                </div>
            </div>
        </div>
    }

    const twoGridContentRideJobReverse = (item) => {
        return <div className="flex lg:space-x-6 flex-col-reverse lg:flex-row side-px">
            <div className="lg:w-1/2 flex items-center">
            {/* className="h-full w-full relative" */}
                <div style={{margin:'0 auto'}}>
                    {/* <img className='rounded-md lg:rounded-38 bg-cover h-full w-full mx-auto object-cover' src={item.image} alt="About Us" /> */}
                    <img className="h-ridejob" src={item.image} alt="About Us" />

                </div>
            </div>

            <div className="lg:w-1/2 pr-10 mb-5 lg:mb-0 lg:mt-0 mt-5 lg:self-center">
                <h2 className="font-bold lg:text-45 sm:text-40">{item.title}</h2>
                {/* <p className="text-14 sm:text-20 mt-4 sm:mt-8 text-justify" >{item.discription}</p> */}
                <p className="text-14 font-medium sm:text-20 mt-4 sm:mt-8 " dangerouslySetInnerHTML={{ __html: item.discription }}></p>

            </div>

        </div>
    }

    function RideJobData(props) {
        return (
            <div className="px-16">
                <div className={`flex flex-col-reverse ${props.isReverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} px-6 lg:px-0`}>
                    {/* <div className="lg:w-2/5 lg:mr-8"> */}
                    {/* {props.segment === "RIDE" && props.car === '1' && <div className="anim_div anim_flex" >
                            <img className="h-10 mr-8" alt="Rides" src={ride} />
                        </div>} */}
                    <div className={`lg:mr-8 ${props.isReverse ? 'lg:w-3/5' : 'lg:w-2/5'}`}>
                        <img className="object-cover h-full w-full" alt="Rides" src={props.image} />

                    </div>
                    {/* <div className="lg:w-3/5 text-center md:text-left self-center"> */}
                    <div className={`text-center md:text-left self-center ${props.isReverse ? 'lg:w-2/5' : 'lg:w-3/5'}`}>
                        <h1 className="text-23 sm:text-45 font-bold">{props.title}</h1>
                        <p className="font-medium text-14 sm:text-20 mt-5 md:w-3/5">{props.discription}</p>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>

            <div className="lg:px-16 sm:px-8">
                <div className="flex flex-col-reverse lg:flex-row px-4 lg:px-0">
                    <div className="lg:w-1/2 text-center md:text-left mt-20 hw-pr">
                        <h1 className="hw-title font-bold">Find A Best Ride & Best Job</h1>
                        <p className="font-medium hw-disc mt-5">Find and Share ride only on Ramarum mobile application, quick and easy access to suitable requirements near you.</p>
                        {/* <div className="flex justify-between items-baseline mt-11"> */}
                        {statasticsData && <div className="flex space-x-10 mt-11 justify-center lg:justify-start">
                            <div>
                                <p className="font-bold text-16 sm:text-24 pt-2.5">{statasticsData?.Rides}+</p>
                                <p className="text-10 sm:text-18 opacity-50 text-center">Rides</p>
                            </div>
                            <div>
                                <p className="font-bold text-16 sm:text-24 pt-2.5">{statasticsData?.Jobs}+</p>
                                <p className="text-10 sm:text-18 opacity-50 text-center">Jobs</p>
                            </div>
                            <div>
                                <p className="font-bold text-16 sm:text-24 pt-2.5">{statasticsData?.Users}+</p>
                                <p className="text-10 sm:text-18 opacity-50 text-center">Users</p>
                            </div>


                        </div>}
                        <div className="flex space-x-5 hw-mt-user justify-center lg:justify-start bnr_down">
                            <MOBILE_DOWNLOAD_LINK />
                        </div>
                    </div>
                    <div className="lg:w-1/2">
                        <img className="inline hw-img" alt="Mobile" src={mgroup} />
                    </div>
                </div>
            </div>
            <div className='px-4'>
                <h1 className="text-20 sm:text-28 font-bold text-center mt-20 mb-11">Easy Getting Started</h1>
                <div className="flex justify-center w-full mb-n-6">
                    <div className="w-2/4 flex lg:justify-end justify-center">
                        {segment === 'RIDE' && <img className="h-10 lg:mr-8 sm:mr-0" alt="Rides" src={ride} />}
                    </div>
                    <div className="w-2/4 flex lg:justify-start justify-center ">
                        {segment === 'JOB' && <img className="h-10  lg:ml-24" alt="Rides" src={job} />}
                    </div>

                </div>
                <div className="flex justify-center pb-14 lg:mb-24">
                    <button className={`hw-btn md:w-1/2 lg:w-auto lg:text-18 sm:text-14 font-bold ${segment === 'RIDE' ? 'btn-primary p-5 rounded-full mr-n-30 z-40 md:pl-14 md:pr-14 lg:pl-14 lg:pr-14 sm:pl-24 sm:pr-24' : 'btn-grey pt-5 pb-5 md:pl-14 md:pr-14 lg:pl-14 lg:pr-14 sm:pl-24 sm:pr-24 rounded-l-full'}`} onClick={() => segmentChange('RIDE')}>
                        How Ride Works?
                    </button>
                    <button className={`hw-btn md:w-1/2 lg:w-auto lg:text-18 sm:text-14 font-bold ${segment === 'JOB' ? 'btn-primary p-5  rounded-full ml-n-30 md:pl-14 md:pr-14 lg:pl-14 lg:pr-14 sm:pl-24 sm:pr-24' : 'btn-grey pt-5 pb-5 md:pl-14 md:pr-14 lg:pl-14 lg:pr-14 sm:pl-24 sm:pr-24 rounded-r-full'}`} onClick={() => segmentChange('JOB')}>
                        How Jobs Work?
                    </button>
                </div>

                {/* {segment === 'RIDE' && <div>
                    <RideJobData  isReverse={false} image={ride1} title="Create account with basic information" discription="Signup in less than 30 Seconds Check your mobile and enter OTP All done to login"></RideJobData>
                    <RideJobData  isReverse={true} image={ride2} title="Find a Ride" discription="Start to chat Make an offer Get notify and updated"></RideJobData>
                    <RideJobData isReverse={false} image={ride3} title="Post A Ride" discription="Quick and easy steps Follow 4-5 steps and post ride Get notify and updated"></RideJobData>
                    <RideJobData isReverse={true} image={ride4} title="Easy manage your profile" discription="Manage your post Manage your personal information Build a trust to using real profile picture"></RideJobData>
                    <RideJobData isReverse={false} image={ride5} title="Get rating or Give rating" discription="Get rating from user Share experience & get business Rating makes trust user to user"></RideJobData>
                </div>}

                {segment === 'JOB' && <div>
                    <RideJobData isReverse={false} image={job1} title="Create account with basic information" discription="Signup in less than 30 Seconds Check your mobile and enter OTP All done to login"></RideJobData>
                    <RideJobData isReverse={true} image={job2} title="Finding a Jobs or Candidates" discription="All displayed near you Start a chat  Make an offer"></RideJobData>
                    <RideJobData isReverse={false} image={job3} title="Post a Jobs or Profile" discription="All displayed near you Start a chat Make an offer"></RideJobData>
                    <RideJobData isReverse={true} image={job4} title="Easy manage your profile" discription="Manage your post Manage your personal information Build a trust to using real profile picture"></RideJobData>
                    <RideJobData isReverse={false} image={job5} title="Get rating or Give rating" discription="Get rating from user Share experience & get business Rating makes trust user to user"></RideJobData>
                </div>} */}

                {segment === 'RIDE' && <div>
                    <div>
                        {twoGridContentRideJobReverse({
                            title: "Create account with basic information",
                            discription: "Signup in less than 30 Seconds<br> Check your mobile and enter OTP<br> All done to login",
                            image: ride1
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJob({
                            title: "Find a Ride",
                            discription: `Start to chat <br>Make an offer<br> Get notify and updated`,
                            image: ride2
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJobReverse({
                            title: "Post A Ride",
                            discription: "Quick and easy steps<br> Follow 4-5 steps and post ride<br> Get notify and updated",
                            image: ride3
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJob({
                            title: "Easy manage your profile",
                            discription: "Manage your post<br> Manage your personal information<br> Build a trust to using real profile picture",
                            image: ride4
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJobReverse({
                            title: "Get rating or Give rating",
                            discription: "Get rating from user<br> Share experience & get business<br> Rating makes trust user to user",
                            image: ride5
                        })}
                    </div>

                   
                    
                </div>}

                {segment === 'JOB' && <div>
                <div>
                        {twoGridContentRideJobReverse({
                            title: "Create account with basic information",
                            discription: "Signup in less than 30 Seconds<br> Check your mobile and enter OTP<br> All done to login",
                            image: job1
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJob({
                            title: "Finding a Jobs or Candidates",
                            discription: "All displayed near you<br> Start a chat<br> Make an offer",
                            image: job2
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJobReverse({
                            title: "Post a Jobs or Profile",
                            discription: "All displayed near you<br>Start a chat<br> Make an offer",
                            image: job3
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJob({
                            title: "Easy manage your profile",
                            discription: "Manage your post<br> Manage your personal information<br> Build a trust to using real profile picture",
                            image: job4
                        })}
                    </div>
                    <div>
                        {twoGridContentRideJobReverse({
                            title: "Get rating or Give rating",
                            discription: "Get rating from user<br> Share experience & get business<br> Rating makes trust user to user",
                            image: job5
                        })}
                    </div>
                </div>}
            </div>
            <BenefitApp></BenefitApp>
            {/* <div className="mt-11">
                <AdvertiseWithUs></AdvertiseWithUs>
            </div> */}
            <div className='mt-28 lg:px-16'>
                <DownLoadOurApp></DownLoadOurApp>
            </div>

        </React.Fragment>
    );
};

export default BestRidesJobs;