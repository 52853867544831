import React from "react";

function InputSearch(props) {

    function onChange(event) {
        props.onChange(event)
    };

    return (
        <React.Fragment>
            <div className="relative">
                <svg className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-4" width="20" height="20" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.92372 0.357788C15.3678 0.357788 19.7961 4.78608 19.7961 10.2302C19.7961 12.7987 18.8104 15.1413 17.1974 16.8995L20.3714 20.0668C20.6684 20.3639 20.6694 20.8444 20.3724 21.1415C20.2244 21.2915 20.0287 21.3655 19.8341 21.3655C19.6404 21.3655 19.4458 21.2915 19.2967 21.1435L16.0845 17.9402C14.3947 19.2934 12.2521 20.1036 9.92372 20.1036C4.4796 20.1036 0.050293 15.6743 0.050293 10.2302C0.050293 4.78608 4.4796 0.357788 9.92372 0.357788ZM9.92372 1.87849C5.31801 1.87849 1.571 5.62449 1.571 10.2302C1.571 14.8359 5.31801 18.5829 9.92372 18.5829C14.5284 18.5829 18.2754 14.8359 18.2754 10.2302C18.2754 5.62449 14.5284 1.87849 9.92372 1.87849Z" fill="black" />
                </svg>

                <input className="rideSearch h-12 py-5 px-14 border-black focus:outline-none w-full text-16" type="text" placeholder={props.placeholder} onChange={onChange} name="search" />
            </div>
        </React.Fragment>
    );
};

export default InputSearch;