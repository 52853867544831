import React, { useEffect, useState, useCallback } from 'react';
import investorImg from '../../assets/images/investor.png';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import { useSelector, useDispatch } from 'react-redux';
import { getInvestors, getMoreInvestors } from '../../store/actions/investor-action';
import { useHistory } from "react-router-dom";
import * as _ from 'lodash';
import moment from 'moment';

function InvestorPage(props) {

    let history = useHistory();
    const [init, setInit] = useState(true);
    const dispatch = useDispatch();

    const postInvestorData = useCallback(
        _.debounce(() => {
            dispatch(getInvestors(0))
        }, 1000),
        []
    );

    const investors = useSelector((state) => {
        return state.investors.investorsTableData;
    });

    const investorsObj = useSelector((state) => {
        return state.investors.investors;
    });

    const hideLoadMore = useSelector((state) => {
        return state.investors.lastPage;
    });


    useEffect(() => {
        if (!init || investors.length === 0) {
            postInvestorData();
        } else {
            setInit(false);
        }
    }, [init]);

    const investorDetails = id => {
        history.push(`/investor/${id}`);
    }

    const loadMore = () => {
        dispatch(getMoreInvestors(investorsObj.nextPageStart))
    }

    return (
        <div>
            <div className="relative mb-16">
                <img className="object-cover w-full max-h-425" alt="Blog" src={investorImg} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35">Investor</h1>
                    <p className="text-12 sm:text-17">We Write The Best Things</p>
                </div>
                <div className="px-5 lg:px-24 mt-14">
                    <div>
                        <div>
                            {/* grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 md:gap-20 gap-4 mt-8 */}
                            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-8">
                                {investors && investors.map((item, i) => (
                                    <div key={i} className="cursor-pointer">
                                        <div className="shadow sm:w-96 sm:min-w-96 p-6 mx-auto" onClick={() => investorDetails(item.id)}>
                                            <p className="font-semibold text-13 sm:text-18 mt-3 h-12">{item.title}</p>
                                            <p className="text-11 sm:text-13 mt-4" dangerouslySetInnerHTML={{ __html: item.content.length > 150 ? `${item.content.slice(0, 150)}....` : item.content }}></p>
                                            <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">{moment(item.created).format('LLL')}</p>
                                            <p className="text-10 sm:text-14 opacity-40 mt-3">{item?.createdby?.first_name}</p>
                                            <p className="font-medium text-12 sm:text-14 text-yellow">Continue Reading...</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        {!hideLoadMore && <div className="flex justify-center mt-16">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}

                    </div>
                </div>
            </div>
        </div >
    );
};

export default InvestorPage;