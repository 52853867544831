import React, { useEffect, useState, useCallback } from 'react';
import sponsorImg from '../../assets/images/sponsor.png';
import InputSearch from '../../components/Input-Search';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import { useSelector, useDispatch } from 'react-redux';
import { getSponsers, getMoreSponsers } from '../../store/actions/sponser-action';
import { useHistory } from "react-router-dom";
import * as _ from 'lodash';
import moment from 'moment';
import './Sponser.css';

function SponserPage(props) {

    let history = useHistory();
    const [data, setData] = useState({
        search: ''
    });
    const [init, setInit] = useState(true);

    const postSponserData = useCallback(
        _.debounce(data => {
            dispatch(getSponsers(data, 0))
        }, 1000),
        []
    );

    const sponsers = useSelector((state) => {
        return state.sponsers.sponsersTableData;
    });

    const sponsersObj = useSelector((state) => {
        return state.sponsers.sponsers;
    });

    const hideLoadMore = useSelector((state) => {
        return state.sponsers.lastPage;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!init || sponsers.length === 0) {
            postSponserData(data)
        } else {
            setInit(false);
        }
    }, [postSponserData, data]);

    const sponserDetails = id => {
        history.push(`/sponser/${id}`);
    }

    const handleChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const loadMore = () => {
        dispatch(getMoreSponsers(data, sponsersObj.nextPageStart))
    }

    return (
        <div>
            <div className="relative mb-16">
                <img className="object-cover w-full max-h-425" alt="Blog" src={sponsorImg} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35">Sponser</h1>
                    <p className="text-12 sm:text-17">We Write The Best Things</p>
                </div>
                <div className="px-5 lg:px-24 mt-14">
                    <div>
                        <InputSearch placeholder="Search Article" onChange={handleChange} />
                        <div>
                            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-8">
                                {sponsers && sponsers.map((item, i) => (
                                    <div key={i} className="cursor-pointer">
                                        <div className="shadow sm:w-96 sm:min-w-96 p-6 mx-auto rounded" onClick={() => sponserDetails(item.id)}>
                                            <div className="">
                                                <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${item.documents[0].display_files.sponsors_thumbnail.square_url})` }}>

                                                </div>
                                            </div>
                                            <div className="h-1/2">
                                                <p className="font-semibold text-13 sm:text-18 mt-3 h-12">{item.title}</p>
                                                <p className="text-11 sm:text-13 mt-4" dangerouslySetInnerHTML={{ __html: item.content.length > 150 ? `${item.content.slice(0, 150)}....` : item.content }}></p>
                                                <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">{moment(item.created).format('LLL')}</p>
                                                <p className="text-10 sm:text-14 opacity-40 mt-3">{item?.createdby?.first_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        {!hideLoadMore && <div className="flex justify-center mt-16">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}

                    </div>
                </div>
            </div>
        </div >
    );
};

export default SponserPage;