
import axios from 'axios';
import { HOW_ITS_WORK } from '../../constants';

export const GET_STATASTICS = 'GET_STATASTICS';
export const getStatastics = () => async dispatch => {
    try {
        const response = await getStatasticsData();
        dispatch({
            type: GET_STATASTICS,
            payload: response.data.result.responce.DataStatistics,
        })
    } catch (error) {

    }
}

const getStatasticsData = () => {
    return axios({
        method: 'get',
        url: HOW_ITS_WORK,
        data: {},
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}