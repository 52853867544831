import { GET_INVESTORS, GET_INVESTORS_DETAILS, GET_MORE_INVESTORS, HIDE_INVESTORS_BUTTON } from '../actions/investor-action';

const initialState = {
    investors: {},
    investorsTableData: [],
    investorsDetails: {},
    lastPage: false,
}

export default function investor(state = initialState, action) {
    switch (action.type) {
        case GET_INVESTORS:
            return {
                ...state,
                investors: action.payload.investors,
                investorsTableData: action.payload.investors.tableData,
                lastPage: action.payload.lastPage
            }
        case GET_MORE_INVESTORS:
            return {
                ...state,
                investors: action.payload,
                investorsTableData: [...state.investorsTableData, ...action.payload.tableData]
            }
        case GET_INVESTORS_DETAILS:
            return {
                ...state,
                investorsDetails: action.payload
            }
        case HIDE_INVESTORS_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}