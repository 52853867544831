import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import NavBar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import HomePage from './pages/Home/Home';
import RidesPage from './pages/Rides/Rides';
import RideDetailsPage from './pages/Rides/RideDetails';
import JobsPage from './pages/Jobs/Jobs';
import JobDetails from './pages/Jobs/JobDetails';
import AboutPage from './pages/About/About';
import BlogPage from './pages/Blog/Blog';
import BlogDetailsPage from './pages/Blog/BlogDetails';
import ContactusPage from './pages/Contactus/Contactus';
import PressPage from './pages/Press/PressPage';
import PressDetailsPage from './pages/Press/PressDetails';
import GuideLinesPage from './pages/GuideLines/GuideLines';
import TermsPage from './pages/Terms/Terms';
import PrivacyPolicyPage from './pages/PrivacyPolicy/PrivacyPolicy';
import RefundPolicyPage from './pages/RefundPolicy/RefundPolicy';
import SponserPage from './pages/Sponser/Sponser';
import SponserDetailsPage from './pages/Sponser/SponserDetails';
import InvestorPage from './pages/Investor/Investor';
import InvestorDetailsPage from './pages/Investor/InvestorDetails';
import AdsPage from './pages/Ads/Ads';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import './App.css';
import SponsorAdsDetailsPage from "./pages/sponsorads/SponsorAdsDetail";
import AdvertiseWithUs from "./components/AdvertiseWithUs";
import BestRidesJobs from "./components/BestRidesJobs";

function App() {

  return (
    <Provider store={store}>
      {/* <div class="flex justify-center items-center">
          <div
            className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500 absolute top-1/2 left-1/2 z-50"
          ></div>
        </div> */}
      <Router basename="/">
        <ScrollToTop />
        <div className="font-body">

          <NavBar />
          <Switch>
            <Route exact path="/rides">
              <RidesPage />
            </Route>
            <Route path="/rides/:id">
              <RideDetailsPage />
            </Route>
            <Route exact path="/sponsorads/:id">
              <SponsorAdsDetailsPage />
            </Route>
            <Route exact path="/jobs">
              <JobsPage />
            </Route>
            <Route path="/jobs/:id">
              <JobDetails />
            </Route>
            <Route exact path="/ads">
              <AdsPage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route exact path="/blog">
              <BlogPage />
            </Route>
            <Route exact path="/blog/:id">
              <BlogDetailsPage />
            </Route>
            <Route path="/contactus">
              <ContactusPage />
            </Route>
            <Route exact path="/press">
              <PressPage />
            </Route>
            <Route exact path="/press/:id">
              <PressDetailsPage />
            </Route>
            <Route path="/guidelines">
              <GuideLinesPage />
            </Route>
            <Route path="/terms">
              <TermsPage />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicyPage />
            </Route>
            <Route path="/refundpolicy">
              <RefundPolicyPage />
            </Route>
            <Route exact path="/sponser">
              <SponserPage />
            </Route>
            <Route exact path="/sponser/:id">
              <SponserDetailsPage />
            </Route>
            <Route exact path="/investor">
              <InvestorPage />
            </Route>
            <Route exact path="/investor/:id">
              <InvestorDetailsPage />
            </Route>
            <Route exact path="/advertisewithus">
              <AdvertiseWithUs />
            </Route>
            <Route exact path="/howitswork">
              <BestRidesJobs />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
