
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import SocialIconsDark from '../../components/Social-Dark';
import { useSelector, useDispatch } from 'react-redux';
import { getSponserDetails } from '../../store/actions/sponser-action';
import moment from 'moment'

function SponserDetailsPage(props) {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getSponserDetails(id))
    }, [dispatch, id]);

    const sponserDetails = useSelector((state) => {
        return state.sponsers.sponsersDetails;
    });

    return (
        <div className="md:container mx-auto md:px-10 pb-14">
            <div className="mt-6 sm:mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                <p>Home &gt; <Link to="/sponser">Sponser</Link> &gt; {sponserDetails?.title}</p>
            </div>
            <div className="mt-6 sm:mt-9">
                <Carousel
                    autoPlay={false}
                    infiniteLoop={true}
                    swipeable={true}
                    dynamicHeight={true}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={false}>
                    {sponserDetails?.documents && <img className="w-full md:rounded max-h-425" alt={sponserDetails?.documents[0]?.title} src={sponserDetails?.documents && sponserDetails.documents[0]?.display_files?.sponsors_thumbnail?.main_url} />}
                </Carousel>
                <div className="px-4 sm:px-0">
                    <div className="mt-8">
                        <h1 className="font-bold text-15 sm:text-31">{sponserDetails?.title}</h1>
                        <p className="opacity-50 text-15 sm:text-20 font-medium">Posted {moment(sponserDetails?.created).fromNow()}</p>
                        <p className="mt-8 text-15 sm:text-20" dangerouslySetInnerHTML={{ __html: sponserDetails?.content }}></p>
                        <div>
                            <SocialIconsDark title="Share This Ride On" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SponserDetailsPage;