import React, { useEffect, useState, useCallback } from 'react';
import rideBg from '../../assets/images/rides-bg.svg';
import rideMobileBg from '../../assets/images/rides-mobile-bg.jpg';
import rideMobilePath from '../../assets/images/rides-mobile-path.svg';
import SaleCard from '../../components/Sale/Sale-Card';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import GetStarted from '../../components/GetStarted';
import DescriptionCard from '../../components/Description/Description-Card';
import Slider from '../../components/Slider/Slider';
import InputSearch from '../../components/Input-Search';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getRides, getMoreRides, getPostMaster } from '../../store/actions/ride-action';
import * as _ from 'lodash';
import './Rides.css';
import BestRidesJobs from '../../components/BestRidesJobs';
import { POST_MASTER } from '../../constants';

function RidesPage(props) {
    let history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        min: 0,
        max: 1,
        step: 100,
        value: { min: 0, max: 1 },
        search: "",
        range: "",
        sort: "asc",
        looking_for: ""
    });

    const postRideData = useCallback(
        _.debounce(data => {
            dispatch(getRides(data, 0))
        }, 1000),
        []
    );

    const postMasterData = useCallback(
        _.debounce(data => {
            dispatch(getPostMaster())
        }, 1000),
        []
    );

    const rides = useSelector((state) => {
        return state.rides.ridesTableData;
    });

    const ridesObj = useSelector((state) => {
        return state.rides.rides;
    });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const handleSliderChange = (obj) => {
        setData({
            ...data,
            value: obj.value
        })
    };

    const saleClick = (item) => {
        history.push(`/rides/${item.id}`);
    }

    const loadMore = () => {
        dispatch(getMoreRides(data, ridesObj.nextPageStart))
    }

    useEffect(() => {
        fetch(POST_MASTER)
          .then(res => res.json())
          .then(data => {
            const post_master=data.result.responce.getPostMaster.Rides;
            if (post_master && post_master.search) {
            setData({
                ...data,
                min: post_master.search.range.min.value,
                max: post_master.search.range.max.value,
                value: {
                    min: post_master.search.range.min.value,
                    max: post_master.search.range.max.value
                }
            });
        }
          });
      }, []);

    useEffect(() => {
        postRideData(data);
    }, [dispatch, data]);

    const hideLoadMore = useSelector((state) => {
        return state.rides.lastPage;
    });

    return (
        <div>
            <div className="pt-12 relative">
                <div className="">
                    <img className="hidden lg:block" src={rideBg} width="100%" height="100%" alt="" />
                    <img className="block lg:hidden" src={rideMobileBg} width="100%" height="100%" alt="" />
                    <div className="lg:absolute lg:w-1/3 left-24 top-24 px-7">
                        <h1 className="hw-title font-bold">Find & Share rides near you.</h1>
                        <p className="font-medium hw-disc mt-5 text-justify">We provide the ultimate solution for those who want to connect business users with candidates who are looking for rides with affordable prices.</p>
                    </div>
                    <img className="block lg:hidden" src={rideMobilePath} width="100%" height="100%" alt="" />
                </div>
            </div>
            <div className="mt-20 container m-auto">
                <div>
                    <div className="text-center">
                        <h1 className="text-19 sm:text-45 font-bold">Popular Rides Near You</h1>
                        <p className="text-12 sm:text-17 mt-1.5">Easy, Fast and Affordable all over the USA</p>
                    </div>
                    <div>
                        <div className="grid grid-cols-1 xl:grid-cols-2 mt-10 px-5">
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                                <div className='mt-10'>
                                    <InputSearch placeholder="Search Ride With Location, Zip code..." onChange={handleChange} />
                                </div>
                                <div className="mt-10">
                                    <Slider data={data} onChange={handleSliderChange} name="range" />
                                </div>
                            </div>
                            <div className="block sm:flex items-center justify-around">
                                <div className="flex items-center mt-10">
                                    <label className="text-18 font-light">Offering/Wanted:</label>
                                    <select className="outline-none font-semibold text-16" name="looking_for" onChange={handleChange}>
                                        <option value="">Both</option>
                                        <option value="RS">Looking For A Ride</option>
                                        <option value="RP">Offering A Ride</option>
                                    </select>
                                </div>
                                <div className="flex items-center mt-10">
                                    <label className="text-18 font-light">Sort By:</label>
                                    <select className="outline-none font-semibold text-16" name="sort" onChange={handleChange}>
                                        <option value="asc">A-Z</option>
                                        <option value="desc">Z-A</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-4 mt-10"> */}
                        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-2 my-11">
                            {rides && rides.map((item, i) => (
                                <div key={i} className="mx-auto">
                                    <SaleCard
                                        displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                        // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                        text={item.address_full}
                                        amount={item.your_amount}
                                        btnType={(item.post_type === 'RS' && 'full') || (item.post_type === 'RP' && 'outline')}
                                        btnText={(item.post_type === 'RS' && <span>Wanted</span>) || (item.post_type === 'RP' && <span>Offering</span>)}
                                        timestamp={item?.created}
                                        saleClick={() => saleClick(item)}
                                    />
                                </div>
                            ))}
                        </div>
                        {!hideLoadMore && <div className="mt-14 hidden sm:flex justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}
                        {!hideLoadMore && <div className="mt-14 flex sm:hidden justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="md" ramarum_click={loadMore} />
                        </div>}
                    </div>
                </div>
                <div className="mt-20 sm:mt-40">
                    <DescriptionCard title1="Easiest way to find ride nearest you"
                    subTitle1="There is a quick setup on a mobile application, start finding  rides.."
                    dataList1={["Free registration","Enough of rides near you","No payment required","Direct chat with users"]}
                    title2="Easiest way to share ride near you"
                    subTitle2="There is a quick setup on a mobile application, start sharing rides."
                    dataList2={["Free registration","Enough ride partner near you","Get more earning","Direct chat with users"]}
                    />
                </div>
                <div className="mt-20 sm:mt-60">
                    <GetStarted />
                </div>
                {/* <div className="mt-20 sm:mt-60">
                    <BestRidesJobs />
                </div> */}
            </div>
        </div>
    );
};

export default RidesPage;

