import React from "react";

function DescriptionCard(props) {
    return (
        <React.Fragment>
            <div className="px-5">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="md:w-5/12 mr-8 pt-6">
                        <h1 className="text-19 sm:text-45 font-bold">{props.title1}</h1>
                        <p className="font-medium text-14 sm:text-20 mt-7">There is a quick setup on a mobile application, start finding  rides..</p>
                        <ul className="font-medium text-14 sm:text-20 mt-5">
                            {/* <li>Free registration </li>
                            <li>Enough of rides near you</li>
                            <li>No payment required</li>
                            <li>Direct chat with users</li> */}
                            {props.dataList1 && props.dataList1.map((item, i) => (
                                 <li>{item} </li>
                            ))}
                        </ul>
                    </div>
                    <div className="relative md:w-7/12">
                        <div className="absolute right-5 -z-1">
                            <svg width="753" height="579" viewBox="0 0 753 579" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="529.966" cy="222.219" r="222.134" fill="url(#paint0_radial)" />
                                <circle opacity="0.2" cx="142.533" cy="436.699" r="141.644" fill="url(#paint1_radial)" />
                                <defs>
                                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(529.966 0.0848389) rotate(90) scale(444.268)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                    <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(142.533 295.055) rotate(90) scale(283.289)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                        <img className="rounded rounded-b-lg z-10" width="100%" height="100%" src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                    </div>
                </div>
            </div>
            <div className="px-5">
                <div className="hidden sm:block absolute left-8 z-0">
                    <svg width="710" height="661" viewBox="0 0 710 661" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="222.554" cy="289.012" r="222.134" fill="url(#paint0_radial)" />
                        <circle opacity="0.2" cx="568.033" cy="518.795" r="141.644" fill="url(#paint1_radial)" />
                        <circle opacity="0.4" cx="476.691" cy="95.9742" r="45.1255" transform="rotate(-155.241 476.691 95.9742)" fill="url(#paint2_radial)" />
                        <circle opacity="0.4" cx="385.054" cy="39.4942" r="27.2595" transform="rotate(-155.241 385.054 39.4942)" fill="url(#paint3_radial)" />
                        <circle opacity="0.3" cx="511.768" cy="19.9189" r="14.7529" transform="rotate(-155.241 511.768 19.9189)" fill="url(#paint4_radial)" />
                        <defs>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(222.554 66.8776) rotate(90) scale(444.268)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(568.033 377.151) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(476.691 50.8487) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(385.054 12.2347) rotate(90) scale(54.519)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(511.768 5.16609) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>

                </div>
                <div className="flex flex-col md:flex-row mt-11 md:mt-60">
                    <div className="md:w-7/12 z-10">
                        <img className="rounded rounded-b-lg" width="100%" height="100%" src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
                    </div>
                    <div className="md:w-5/12 ml-8 pt-6">
                        <h1 className="text-19 sm:text-45 font-bold">{props.title2}</h1>
                        <p className="font-medium text-14 sm:text-20 mt-7">{props.subTitle2}</p>
                        <ul className="font-medium text-14 sm:text-20 mt-5">
                            {/* <li>Free registration</li>
                            <li>Enough ride partner near you</li>
                            <li>Get more earning</li>
                            <li>Direct chat with users</li> */}
                             {props.dataList2 && props.dataList2.map((item, i) => (
                                 <li>{item} </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default DescriptionCard;