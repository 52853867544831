import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Footer.css';
// import logo from '../../assets/images/logo-light.svg';
import logo from '../../assets/images/logo-light.png';
import facebook from '../../assets/images/social/facebook.svg';
import twitter from '../../assets/images/social/twitter.svg';
import linkedin from '../../assets/images/social/linkedin.svg';
import instagram from '../../assets/images/social/instagram.svg';
import youtube from '../../assets/images/social/youtube.svg';
import appstore from '../../assets/images/mobile/appStoreLight.svg';
import googlePlay from '../../assets/images/mobile/googlePlayLight.svg';
import RAMARUM_BUTTON from '../Ramarum-Button';
import send from '../../assets/images/send.svg';
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import { SUBSCRIBE } from '../../constants';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function Footer(props) {
    let history = useHistory();
    const defaultValues = {
        email: ''
    };
    const defaultValidateValues = {
        email: false
    };
    const [data, setData] = useState({ ...defaultValues });
    const [dataValidate, setDataValidate] = useState({ ...defaultValidateValues });

    const subscribe = async () => {
        if (data.email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            setDataValidate({ ...defaultValidateValues });
            try {
                const response = await axios({
                    method: 'post',
                    url: SUBSCRIBE,
                    data: qs.stringify({ ...data }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
                if (response.data.code === 200) {
                    notifySuccess(response.data.result.success);
                    setData({ ...defaultValues });
                } else {
                    notifyError(response.data.result.error);
                }
            } catch (error) {
                notifyError("There's some technical error. Please try again after some time.");
            }
        } else {
            setDataValidate({ ...defaultValidateValues, email: true });
        }
    }

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const gotoHome = () => {
        history.push(`/`);
    }

    const notifySuccess = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    const notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    return (
        <div className="bg-grey">
            {/* pt-6 md:pt-14 px-8 md:pr-14 md:pl-24 */}
            <div className="pt-6 md:pt-14 px-5 lg:px-24">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    <div className="pr-4">
                        <img className="block h-55 w-200 my-3 cursor-pointer" src={logo} alt="Logo" onClick={gotoHome} />
                        <p className="text-light mt-5"> Ramarum best leading destination to get a ride, share a ride, find a job, and offer a job in the retail sector. We are American professionals based in South Carolina who come to make life and more comfort around you.</p>
                        <div className="flex mt-10 space-x-5">
                            {/* <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ramarum.com%2F&amp;src=sdkpreparse" target="_blank">
                            <img alt="Facebook" src={facebook} className="cursor-pointer" />
                            </a>
                            <a href="http://twitter.com/share?url=https://www.ramarum.com" target="_blank">
                            <img alt="Twitter" src={twitter} className="cursor-pointer" />
                            </a>
                            <a href="https://www.linkedin.com/shareArticle?url=https://www.ramarum.com" target="_blank">
                            <img alt="Linkedin" src={linkedin} className="cursor-pointer" />
                            </a> */}
                            {/* <img alt="Facebook" src={facebook} className="cursor-pointer" />
                            <img alt="Twitter" src={twitter} className="cursor-pointer" />
                            <img alt="Linkedin" src={linkedin} className="cursor-pointer" />
                            <img alt="Instagram" src={instagram} className="cursor-pointer" />
                            <img alt="Youtube" src={youtube} className="cursor-pointer" /> */}

                            <a href="https://www.facebook.com/ramarumofficial/" target="_blank">
                            <img alt="Facebook" src={facebook} className="cursor-pointer" />
                            </a>
                            <a href="https://twitter.com/ramarumofficial" target="_blank">
                            <img alt="Twitter" src={twitter} className="cursor-pointer" />
                            </a>
                            <a href="https://www.linkedin.com/company/ramarumofficial" target="_blank">
                            <img alt="Linkedin" src={linkedin} className="cursor-pointer" />
                            </a>
                            <a href="https://www.instagram.com/ramarumofficial/" target="_blank">
                            <img alt="Instagram" src={instagram} className="cursor-pointer" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCMcdnOiP7oDgvAEHrkn-esQ" target="_blank">
                            <img alt="Youtube" src={youtube} className="cursor-pointer" />
                            </a>
                        </div>
                        <div className="flex space-x-5">
                            <img className="mt-12 cursor-pointer w-full" alt="App Store" src={appstore} />
                            <img className="mt-12 cursor-pointer w-full" alt="Google Play" src={googlePlay} />
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-flow-col grid-cols-2 mt-10">
                            <div>
                                <p className="text-white font-bold">About</p>
                                <ul className="text-light">
                                    <li className="mt-3.5"><Link to="/">Home</Link></li>
                                    <li className="mt-3.5"><Link to="/about">About Us</Link></li>
                                    <li className="mt-3.5"><Link to="/rides">Rides</Link></li>
                                    <li className="mt-3.5"><Link to="/jobs">Jobs</Link></li>
                                    <li className="mt-3.5"><Link to="/blog">Blog</Link></li>
                                    <li className="mt-3.5"><Link to="/contactus">Contact Us</Link></li>
                                    <li className="mt-3.5"><Link to="/advertisewithus">Advertise With Us</Link></li>
                                    <li className="mt-3.5"><Link to="/howitswork">How It Works</Link></li>
                                </ul>
                            </div>
                            <div>
                                <p className="text-white font-bold">Information</p>
                                <ul className="text-light mt-3.5">
                                    <li className="mt-3.5"><Link to="/press">Press Release</Link></li>
                                    <li className="mt-3.5"><Link to="/terms">Terms &amp; Conditions</Link></li>
                                    <li className="mt-3.5"><Link to="/privacypolicy">Privacy Policy</Link></li>
                                    <li className="mt-3.5"><Link to="/investor">Investor Relations</Link></li>
                                    <li className="mt-3.5"><Link to="/guidelines">Covid-19 Guideline</Link></li>
                                    <li className="mt-3.5"><Link to="/refundpolicy">Refund Policy</Link></li>
                                    <li className="mt-3.5"><Link to="/sponser">Sponsor</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 pr-4">
                        <p className="text-white font-bold">Get Connected</p>
                        <p className="text-light mt-3.5">Free Subcribe, you will get up to date about jobs, rides and other beneficials.</p>
                        <div className="bg-mediumgrey rounded mt-8 w-full sm:w-72 p-4">
                            <div>
                                <input className="bg-transparent subscribe w-full" type="text" name="email" value={data.email} placeholder="Enter Your Email Here" onChange={handleChange} />
                                {dataValidate.email && <span className="text-12 text-red">Invalid Email address</span>}
                            </div>
                            <div className="mt-7 subscribeBtn">
                                <RAMARUM_BUTTON text="Subscribe" size="lg" icon={send} ramarum_click={subscribe} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <p className="text-light text-center text-12 sm:text-14 border-t border-lightgrey py-5">&copy; {moment().year()} by Ramarum, LLC. All rights reserved </p>
            </div>
        </div>
    );
};

export default Footer;