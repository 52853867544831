import qs from 'qs';
import axios from 'axios';
import { POST_MASTER, RIDES, RIDES_DETAILS } from '../../constants';

export const GET_RIDES = 'GET_RIDES';
export const GET_RIDES_DETAILS = 'GET_RIDES_DETAILS';
export const HIDE_RIDES_BUTTON = 'HIDE_RIDES_BUTTON';
export const GET_MORE_RIDES = 'GET_MORE_RIDES';
export const GET_POST_MASTER = 'GET_POST_MASTER';

export const getRides = (request, pageNo) => async dispatch => {
    try {
        const response = await getRidesByRequestData(request, pageNo);

        dispatch({
            type: GET_RIDES,
            payload: response.data.result.responce.Rides,
            postMaster:response.data.result.responce.PostMaster,
            showLoadMore: false
        })
        if (response?.data?.result?.responce?.Rides?.tableData?.length === 0) {
            dispatch({
                type: HIDE_RIDES_BUTTON,
                payload: true
            })
        }

    } catch (error) {

    }
}

export const getMoreRides = (request, pageNo) => async dispatch => {
    try {
        const response = await getRidesByRequestData(request, pageNo);
        if (response?.data?.result?.responce?.Rides?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_RIDES,
                payload: response.data.result.responce.Rides,
                showLoadMore: false
            })
        } else {
            dispatch({
                type: HIDE_RIDES_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

const getRidesByRequestData = (request, pageNo) => {
    return axios({
        method: 'post',
        url: RIDES,
        data: qs.stringify({
            'filter[post_type]': 'Rides',
            'filter[looking_for]': request.looking_for,
            'filter[search]': request.search,
            'filter[amount_min]': request.value.min,
            'filter[amount_max]': request.value.max,
            'sort[address]': request.sort,
            start: pageNo
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const getRidesDetails = id => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: `${RIDES_DETAILS}/${id}`,
            data: qs.stringify({
                [id]: 'Rides'
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        dispatch({
            type: GET_RIDES_DETAILS,
            payload: {
                posts: response.data.result.responce.Posts,
                morePosts: response.data.result.responce.morePosts
            }
        })
    } catch (error) {

    }
}

export const getPostMaster = () => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: `${POST_MASTER}`,
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        dispatch({
            type: GET_POST_MASTER,
            payload: response.data.result.responce
        })
    } catch (error) {

    }
}

