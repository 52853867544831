import React, { useEffect, useState } from 'react';


function Accordian(props) {

    const [accordians, setAccordians] = useState([]);

    useEffect(() => {
        let accord = props.accordianData;
        accord.forEach((data, i) => {
            if(i === 0) {
                accord[i]["isActive"] = true;
            }else {
                accord[i]["isActive"] = false;
            }
        });
        setAccordians(accord);
    }, [props.accordianData]);

    function updateAccordian(index) {
        let accords = accordians;
        accords[index].isActive = !accords[index].isActive;
        setAccordians([...accords]);
    }

    return (
        <div>
            {accordians && accordians.map((data, idx) => (<div key={idx}>
                <div
                    className={`mt-4 flex justify-between h-16 text-13 sm:text-22 font-semibold p-6 rounded ${data.isActive ? 'bg-lightOrange' : 'shadow'} items-center cursor-pointer`}
                    role="alert"
                    onClick={() => updateAccordian(idx)}>
                    {data.title}
                    {data?.isActive && <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.1905 12.7818C23.7797 13.1925 23.137 13.2298 22.6842 12.8938L22.5544 12.7818L12.575 2.80291L2.59564 12.7818C2.18493 13.1925 1.54223 13.2298 1.08934 12.8938L0.959589 12.7818C0.548876 12.3711 0.511537 11.7284 0.847576 11.2755L0.959589 11.1457L11.757 0.348307C12.1677 -0.0624064 12.8104 -0.0997447 13.2633 0.236293L13.3931 0.348307L24.1905 11.1457C24.6422 11.5975 24.6422 12.33 24.1905 12.7818Z" fill="black" />
                    </svg>}
                    {!data?.isActive && <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2" d="M0.959932 0.805635C1.37064 0.394922 2.01334 0.357585 2.46623 0.693622L2.59599 0.805635L12.5754 10.7845L22.5547 0.805635C22.9655 0.394922 23.6082 0.357585 24.0611 0.693622L24.1908 0.805635C24.6015 1.21635 24.6389 1.85905 24.3028 2.31194L24.1908 2.44169L13.3934 13.2391C12.9827 13.6498 12.34 13.6871 11.8871 13.3511L11.7573 13.2391L0.959932 2.44169C0.508148 1.98991 0.508148 1.25742 0.959932 0.805635Z" fill="black" />
                    </svg>}

                </div>
                {data?.isActive && <div className="p-6 text-12 sm:text-20" dangerouslySetInnerHTML={{ __html: data.content }}>
                </div>}
            </div>))}
        </div>
    );
};

export default Accordian;