import qs from 'qs';
import axios from 'axios';
import { SPONSORS, SPONSOR_DETAILS } from '../../constants';

export const GET_SPONSERS = 'GET_SPONSERS';
export const GET_SPONSERS_DETAILS = 'GET_SPONSERS_DETAILS';
export const HIDE_SPONSERS_BUTTON = 'HIDE_SPONSERS_BUTTON';
export const GET_MORE_SPONSERS = 'GET_MORE_SPONSERS';

export const getSponsers = (request, pageNo) => async dispatch => {
    try {
        const response = await getSponsersByRequestData(request, pageNo);

        dispatch({
            type: GET_SPONSERS,
            payload: {
                sponsers: response.data.result.responce.Sponsors,
                showLoadMore: false
            },
        })

        if (response?.data?.result?.responce?.Sponsors?.tableData?.length === 0) {
            dispatch({
                type: HIDE_SPONSERS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getMoreSponsers = (request, pageNo) => async dispatch => {
    try {
        const response = await getSponsersByRequestData(request, pageNo);

        if (response?.data?.result?.responce?.Sponsors?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_SPONSERS,
                payload: response.data.result.responce.Sponsors
            })
        } else {
            dispatch({
                type: HIDE_SPONSERS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

const getSponsersByRequestData = (request, pageNo) => {
    return axios({
        method: 'post',
        url: SPONSORS,
        data: qs.stringify({
            'filter[search]': request.search,
            start: pageNo
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const getSponserDetails = id => async dispatch => {
    try {
        const response = await axios.get(`${SPONSOR_DETAILS}/${id}`);
        dispatch({
            type: GET_SPONSERS_DETAILS,
            payload: response.data.result.responce.Sponsors
        })
    } catch (error) {

    }
}