import React, { useEffect, useState } from "react";
import OurPartners from '../../components/OurPartners/OurPartners';
import GetStarted from '../../components/GetStarted';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeData } from '../../store/actions/home-action';
import aboutus from '../../assets/images/Aboutus.jpg';
import ourmission from '../../assets/images/Ourmission.png';
import ourvision from '../../assets/images/Ourvision.png';
import ourstory from '../../assets/images/Ourstory.jpg';
// import whyramarum from '../../assets/images/Whyramarum.jpg';
import whyramarum from '../../assets/images/Whyramarum.jpeg';
import './About.css';
import { isEmpty }from 'lodash';

function AboutPage(props) {

    const dispatch = useDispatch();
    const style = document.createElement('style');
    style.type = 'text/css';

    document.getElementsByTagName('head')[0].appendChild(style);

    const homeData = useSelector((state) => {
        return state.home;
    });

    useEffect(() => {
        if(isEmpty(homeData?.Partners)) {
            dispatch(getHomeData())
        }
    }, [dispatch]);


    const twoGridContent = (item) => {
        return <div className="flex mt-8 lg:mt-36 lg:space-x-6 flex-col-reverse lg:flex-row">
            <div className="lg:w-1/2 pr-10 mt-14 lg:mt-0">
                <h2 className="font-bold text-25 sm:text-35">{item.title}</h2>
                <p className="text-14 sm:text-20 mt-4 sm:mt-8 text-justify" dangerouslySetInnerHTML={{ __html: item.content }}></p>
            </div>
            <div className="lg:w-1/2 flex items-center">
                <div className="lg:p-10 h-full w-full relative">
                    <div className="absolute twoGridImage hidden lg:block">
                        <svg width="560" height="468" viewBox="0 0 560 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.2" cx="175.447" cy="175.371" r="174.926" fill="url(#paint0_radial_102_2262)" />
                            <circle opacity="0.2" cx="447.505" cy="356.321" r="111.542" fill="url(#paint1_radial_102_2262)" />
                            <defs>
                                <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.447 0.444702) rotate(90) scale(349.853)">
                                    <stop stopColor="#FFE6B3" />
                                    <stop offset="0.442708" stopColor="#FFD171" />
                                    <stop offset="1" stopColor="#F7AC49" />
                                </radialGradient>
                                <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.505 244.779) rotate(90) scale(223.084)">
                                    <stop stopColor="#FFE6B3" />
                                    <stop offset="0.442708" stopColor="#FFD171" />
                                    <stop offset="1" stopColor="#F7AC49" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <img className='rounded-md lg:rounded-38 bg-cover h-full w-full mx-auto object-cover' src={item.image} alt="About Us" />

                </div>
            </div>
        </div>
    }

    return (
        <div className="px-5 sm:px-28">
            <div className="topSvg absolute hidden lg:block">
                <svg width="124" height="100" viewBox="0 0 124 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.4" cx="87.3435" cy="66.4447" r="32.9942" transform="rotate(-155.241 87.3435 66.4447)" fill="url(#paint0_radial_102_2262)" />
                    <circle opacity="0.4" cx="20.3411" cy="25.1486" r="19.9312" transform="rotate(-155.241 20.3411 25.1486)" fill="url(#paint1_radial_102_2262)" />
                    <circle opacity="0.3" cx="112.991" cy="10.8356" r="10.7868" transform="rotate(-155.241 112.991 10.8356)" fill="url(#paint2_radial_102_2262)" />
                    <defs>
                        <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(87.3435 33.4505) rotate(90) scale(65.9883)">
                            <stop stopColor="#FFE6B3" />
                            <stop offset="0.442708" stopColor="#FFD171" />
                            <stop offset="1" stopColor="#F7AC49" />
                        </radialGradient>
                        <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.3411 5.21738) rotate(90) scale(39.8624)">
                            <stop stopColor="#FFE6B3" />
                            <stop offset="0.442708" stopColor="#FFD171" />
                            <stop offset="1" stopColor="#F7AC49" />
                        </radialGradient>
                        <radialGradient id="paint2_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(112.991 0.0488787) rotate(90) scale(21.5735)">
                            <stop stopColor="#FFE6B3" />
                            <stop offset="0.442708" stopColor="#FFD171" />
                            <stop offset="1" stopColor="#F7AC49" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <div>
                {twoGridContent({
                    title: "Who We Are?",
                    content: `We direct the way the world moves for the better.
                    Ramarum is the best destination to get a ride, share a ride, find a job, and offer a job in the retail sector. We are American professionals based in South Carolina who come to make life and more comfort around you. Purpose of behind this amazing idea is that we are looking to get ride, share rides, jobs and offer jobs to those who meet us on our digital platform. We provide the ultimate solution for those who want to connect business users with candidates who are looking for jobs with affordable prices.
                    `,
                    image: aboutus
                })}
            </div>
            <div className="lg:flex lg:justify-between lg:space-x-6 mt-8 lg:mt-40 relative">
                <div className="absolute hidden lg:block centerSvg">
                    <svg width="69" height="415" viewBox="0 0 69 415" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="-94.9184" cy="272.995" r="141.644" transform="rotate(-1.03198 -94.9184 272.995)" fill="url(#paint0_radial_102_2262)" />
                        <circle opacity="0.4" cx="23.6096" cy="45.8162" r="45.1255" transform="rotate(-1.03198 23.6096 45.8162)" fill="url(#paint1_radial_102_2262)" />
                        <circle opacity="0.5" cx="25.1177" cy="129.557" r="14.7529" transform="rotate(-1.03198 25.1177 129.557)" fill="url(#paint2_radial_102_2262)" />
                        <defs>
                            <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-94.9184 131.351) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.6096 0.69075) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.1177 114.805) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>

                </div>
                <div className="mt-14 lg:mt-0">
                    <div className='relative'>
                        <img className='rounded-md lg:rounded-38 bg-cover h-80 w-full object-fill' src={ourmission} alt="Our Mission" />
                        <div className="absolute singleGridImage hidden lg:block">
                            <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="111.678" cy="112.296" r="111.542" fill="url(#paint0_radial_259_4269)" />
                                <defs>
                                    <radialGradient id="paint0_radial_259_4269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.678 0.753845) rotate(90) scale(223.084)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <h2 className="text-25 sm:text-35 font-bold mt-16">Our Mission</h2>
                    <p className="text-14 sm:text-20 mt-8 text-justify">Ramarum Mobile application makes life easier and more comfortable than ever before, We are frequently working according to the needs of our users and almost covered the USA.</p>
                </div>
                <div className="mt-14 lg:mt-0">
                    <div className="relative">
                        <img className='rounded-md lg:rounded-38 bg-cover h-80 w-full object-fill' src={ourvision} alt="Our Vision" />
                        <div className="absolute singleGridImage hidden lg:block">
                            <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="111.678" cy="112.296" r="111.542" fill="url(#paint0_radial_259_4269)" />
                                <defs>
                                    <radialGradient id="paint0_radial_259_4269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.678 0.753845) rotate(90) scale(223.084)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <h2 className="text-25 sm:text-35 font-bold mt-16">Our Vision</h2>
                    <p className="text-14 sm:text-20 mt-8 text-justify">Everyone uses our Ramarum mobile application, we are happy to serve to them their life needy transport solution with part of earring even we are try to connect people with employers and candidates to get their dream jobs.</p>
                </div>
            </div>
            <div>
                {twoGridContent({
                    title: "Our Story",
                    content: `We are an american professional base in south carolina decided to start something new and finally after surveying the market we know what difficult people have about transportation and getting a job.  We create ideas  behind how we can make it easy and comfortable for everyone by you using one platform. Two Patel brothers (Raj & Hitesh) give their dedication to the team to create this ultimate platform.`,
                    image: ourstory
                })}
            </div>
            <div className="mt-8 lg:mt-44 relative">
                <div className="absolute hidden lg:block centerSvg">
                    <svg width="69" height="415" viewBox="0 0 69 415" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="-94.9184" cy="272.995" r="141.644" transform="rotate(-1.03198 -94.9184 272.995)" fill="url(#paint0_radial_102_2262)" />
                        <circle opacity="0.4" cx="23.6096" cy="45.8162" r="45.1255" transform="rotate(-1.03198 23.6096 45.8162)" fill="url(#paint1_radial_102_2262)" />
                        <circle opacity="0.5" cx="25.1177" cy="129.557" r="14.7529" transform="rotate(-1.03198 25.1177 129.557)" fill="url(#paint2_radial_102_2262)" />
                        <defs>
                            <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-94.9184 131.351) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.6096 0.69075) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.1177 114.805) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>

                </div>
                <div className="relative">
                    <img className='rounded-md lg:rounded-38 bg-cover h-full w-full' src={whyramarum} alt="Why Ramarum" />
                    <div className="absolute singleGridImage hidden lg:block">
                        <svg width="224" height="224" viewBox="0 0 224 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.2" cx="111.678" cy="112.296" r="111.542" fill="url(#paint0_radial_259_4269)" />
                            <defs>
                                <radialGradient id="paint0_radial_259_4269" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.678 0.753845) rotate(90) scale(223.084)">
                                    <stop stopColor="#FFE6B3" />
                                    <stop offset="0.442708" stopColor="#FFD171" />
                                    <stop offset="1" stopColor="#F7AC49" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <h2 className="text-center font-bold text-25 sm:text-35 mt-10">Why Ramarum?</h2>
                <p className="mt-7 text-14 sm:text-20 text-justify">America has a vast market in business and we are here. Ramarum mobile application makes solutions between ride seekers and car owners. It gives both an opportunity to earn and get a ride who do not have a car. On the other hand,it makes a connection with those who find a job and offers a job as an employer.</p>
                <ul className="text-14 sm:text-20 mt-10">
                    <li>Affordable</li>
                    <li>Earning platform</li>
                    <li>Easy to access</li>
                    <li>User Friendly design</li>
                    <li>Everything in your pocket</li>
                    <li>Get rated</li> 
                    <li>Easy to communicate direct</li>
                    <li>Ultimate Application features</li>
                </ul>
            </div>
            <div className="mt-24">
                <OurPartners style={style} partners={homeData?.Partners?.tableData} />
            </div>
            <div className="mt-24">
                <GetStarted />
            </div>
        </div>
    );
};

export default AboutPage;