import React, { useEffect, useState, useCallback } from 'react';
import blogImg from '../../assets/images/blog.jpg';
import InputSearch from '../../components/Input-Search';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import { useSelector, useDispatch } from 'react-redux';
import { getBlogs, getMoreBlogs } from '../../store/actions/blog-action';
import { useHistory } from "react-router-dom";
import * as _ from 'lodash';
import moment from 'moment';

function BlogPage(props) {

    let history = useHistory();
    const [data, setData] = useState({
        search: '',
        category_id: '',
        blog_tag: ''
    });
    const [init, setInit] = useState(true);

    const postBlogData = useCallback(
        _.debounce(data => {
            dispatch(getBlogs(data, '', '', 0))
        }, 1000),
        []
    );

    const blogs = useSelector((state) => {
        return state.blogs.blogsTableData;
    });

    const blogsObj = useSelector((state) => {
        return state.blogs.blogs;
    });

    const blogTags = useSelector((state) => {
        return state.blogs.blogTags;
    });

    const blogCategories = useSelector((state) => {
        return state.blogs.blogCategories;
    });

    const hideLoadMore = useSelector((state) => {
        return state.blogs.lastPage;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!init || blogs.length === 0) {
            postBlogData(data)
        } else {
            setInit(false);
        }
    }, [postBlogData, data]);

    const blogDetails = id => {
        history.push(`/blog/${id}`);
    }

    const handleChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const loadMore = () => {
        dispatch(getMoreBlogs(data, blogsObj.nextPageStart))
    }

    const handleCatagory = (value, uncheck) => {
        setInit(false);
        setData({
            ...data,
            category_id: uncheck ? '' : value
        })
    }

    const handleTags = (value, uncheck) => {
        setInit(false);
        setData({
            ...data,
            blog_tag: uncheck ? '' : value
        })
    }

    return (
        <div>
            <div className="relative mb-16">
                <img className="object-cover w-full max-h-425" alt="Blog" src={blogImg} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35">Blog</h1>
                    <p className="text-12 sm:text-17">We Write The Best Things</p>
                </div>
                <div className="lg:hidden block px-5 mt-6">
                    <InputSearch placeholder="Search Article" />
                </div>
                <div className="flex px-5 lg:px-24 mt-14 flex-col-reverse lg:flex-row">
                    <div className="lg:w-2/3">
                        <div className="hidden lg:block">
                            <InputSearch placeholder="Search Article" onChange={handleChange} />
                        </div>

                        <div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-8">
                                {blogs && blogs.map((item, i) => (
                                    <div key={i} className="cursor-pointer">
                                        <div className="rounded shadow sm:w-96 sm:min-w-96 p-6 mx-auto min-h-425 max-h-425" onClick={() => blogDetails(item.id)}>
                                            <div className="">
                                                <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${item.documents[0].display_files.blog_feature_img.square_url})` }}>

                                                </div>
                                            </div>
                                            <div className="h-1/2">
                                                <p className="font-semibold text-13 sm:text-18 mt-3">{item.title}</p>
                                                <p className="text-11 sm:text-13 mt-4 text-justify" dangerouslySetInnerHTML={{ __html: item.content.length > 150 ? `${item.content.slice(0, 150)}....` : item.content }}></p>
                                                <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">{moment(item.created).format('LLL')}</p>
                                                <p className="text-10 sm:text-14 opacity-40 mt-3">{item?.createdby?.first_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        {!hideLoadMore && <div className="flex justify-center mt-16">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}

                    </div>
                    <div className="lg:w-1/3 lg:pl-24">
                        <div>
                            <h2 className="font-semibold text-18">Categories:</h2>
                            <div className="mt-2 flex lg:block overflow-x-scroll lg:overflow-x-hidden">
                                {blogCategories && blogCategories.map((item, i) => (<span onClick={() => handleCatagory(item.text, data.category_id === item.text)} key={i} className={`text-12 sm:text-14 black-border rounded-25 px-6 py-2 mr-5 whitespace-nowrap inline-block mt-3 sm:mt-4 cursor-pointer ${data.category_id === item.text ? 'bg-lightYellow' : ''}`}>{item.text}</span>))}
                            </div>
                        </div>
                        <div className="mt-11">
                            <h2 className="font-semibold text-18">Tags:</h2>
                            <div className="mt-2 flex lg:block overflow-x-scroll lg:overflow-x-hidden">
                                {blogTags && blogTags.map((item, i) => (<span onClick={() => handleTags(item.text, data.blog_tag === item.text)} key={i} className={`text-12 sm:text-14 black-border rounded-25 px-6 py-2 mr-5 whitespace-nowrap inline-block mt-3 sm:mt-4 cursor-pointer ${data.blog_tag === item.text ? 'bg-lightYellow' : ''}`}>{item.text}</span>))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BlogPage;