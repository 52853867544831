import { GET_BLOGS, GET_BLOGS_DETAILS, GET_MORE_BLOGS, HIDE_BLOGS_BUTTON } from '../actions/blog-action';

const initialState = {
    blogs: {},
    blogsTableData: [],
    blogTags: [],
    blogCategories: [],
    blogDetails: {},
    lastPage: false,
}

export default function blog(state = initialState, action) {
    switch (action.type) {
        case GET_BLOGS:
            return {
                ...state,
                blogs: action.payload.blogs,
                blogsTableData: action.payload.blogs.tableData,
                blogTags: action.payload.blogTags,
                blogCategories: action.payload.blogCategories,
                lastPage: action.payload.lastPage
            }
        case GET_MORE_BLOGS:
            return {
                ...state,
                blogs: action.payload,
                blogsTableData: [...state.blogsTableData, ...action.payload.tableData]
            }
        case GET_BLOGS_DETAILS:
            return {
                ...state,
                blogDetails: action.payload
            }
        case HIDE_BLOGS_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}