import React, { useEffect, useState } from 'react';
import contactus from '../../assets/images/contactus.jpg'
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import Accordian from '../../components/Accordion/Accordion';
import phoneIcon from '../../assets/icons/phone.svg';
import emailIcon from '../../assets/icons/email.svg';
import timeIcon from '../../assets/icons/time.svg';
import locationIcon from '../../assets/icons/place.svg';
import SocialIconsDark from '../../components/Social-Dark';
import { useSelector, useDispatch } from 'react-redux';
import { getFaqs } from '../../store/actions/contactus-action';
import { CONTACT_US } from '../../constants';
import qs from 'qs';
import axios from 'axios';
import './Contactus.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


function ContactusPage(props) {

    const dispatch = useDispatch();
    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    };
    const defaultValidateValues = {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,
        message: false
    };
    const [data, setData] = useState({ ...defaultValues });
    const [dataValidate, setDataValidate] = useState({ ...defaultValidateValues });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    useEffect(() => {
        dispatch(getFaqs())
    }, [dispatch]);

    const accordianArray = useSelector((state) => {
        if (state?.contactus?.faqs?.tableData?.length > 0) {
            let arr = [];
            state.contactus.faqs.tableData.forEach(ele => {
                arr.push({
                    title: ele.question,
                    content: ele.answer
                })
            });
            return arr;
        }
    });

    const sendMessage = async e => {
        e.preventDefault();
        try {
            if (validateMessage()) {
                const response = await axios({
                    method: 'post',
                    url: CONTACT_US,
                    data: qs.stringify({ ...data }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
               
                if (response.data.code === 200) {
                    notifySuccess(response.data.result.success);
                    setData({ ...defaultValues });
                }else{
                    notifyError(response.data.result.error);
                }
            }
        } catch (error) { 
            notifyError("There's some technical error. Please try again after some time.");
        }
    }

    const validateMessage = () => {
        let isValid = true;
        let obj = { ...defaultValidateValues };
        if (data.first_name?.length > 60 || data.first_name?.length === 0) {
            obj.first_name = true;
            isValid = false;
        }
        if (data.last_name?.length > 60 || data.last_name?.length === 0) {
            obj.last_name = true;
            isValid = false;
        }
        if (!data.email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            obj.email = true;
            isValid = false;
        }
        if (!data.phone || data.phone?.match(/\d/g)?.length !== 10) {
            obj.phone = true;
            isValid = false;
        }
        if (data.subject?.length > 50 || data.subject?.length === 0) {
            obj.subject = true;
            isValid = false;
        }
        if (data.message?.length > 300 || data.message?.length === 0) {
            obj.message = true;
            isValid = false;
        }
        setDataValidate({ ...obj });
        return isValid;
    }

    const notifySuccess = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    const notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    return (
        <div className="mb-32">
            <div className="relative">
                <img className="object-cover w-full max-h-425" alt="contact us" src={contactus} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35">Contact Us</h1>
                    <p className="text-12 sm:text-17">Feel free to write us suggestions , complaints & feedback.</p>
                </div>
            </div>
            <div className="mt-7 sm:mt-24 container mx-auto px-10">
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-2/3 relative">
                        <div className="sm:shadow sm:p-12 bg-white rounded-2xl">
                            <h2 className="font-bold text-31">Information</h2>
                            <p className="text-14 mt-4">Each inquiry is important for us, Team Ramarum will contact you within 24 hours.</p>
                            <form>
                                <div className="flex flex-col sm:flex-row sm:mt-8 mt-5">
                                    <div className='sm:w-1/2'>
                                        <label className="block text-14">First Name:</label>
                                        <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="text" value={data.first_name} name="first_name" onChange={handleChange} />
                                        {dataValidate.first_name && <span className="text-12 text-red">Invalid First Name</span>}
                                    </div>
                                    <div className="sm:ml-8 sm:w-1/2">
                                        <label className="block text-14">Last Name:</label>
                                        <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="text" value={data.last_name} name="last_name" onChange={handleChange} />
                                        {dataValidate.last_name && <span className="text-12 text-red">Invalid Last Name</span>}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className="block text-14">Email:</label>
                                    <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="email" value={data.email} name="email" onChange={handleChange} />
                                    {dataValidate.email && <span className="text-12 text-red">Invalid Email address</span>}
                                </div>
                                <div className="mt-4">
                                    <label className="block text-14">Phone Number</label>
                                    <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="tel" value={data.phone} name="phone" onChange={handleChange} />
                                    {dataValidate.phone && <span className="text-12 text-red">Invalid Phone address</span>}
                                </div>
                                <div className="mt-4">
                                    <label className="block text-14">Subject</label>
                                    <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="text" value={data.subject} name="subject" onChange={handleChange} />
                                    {dataValidate.subject && <span className="text-12 text-red">Last Name cannot be more than 50 characters</span>}
                                </div>
                                <div className="mt-4">
                                    <label className="block text-14">Your message</label>
                                    <textarea className="outline-none black-border rounded-7 px-4 w-full mt-1 h-28" name="message" value={data.message} onChange={handleChange} />
                                    {dataValidate.message && <span className="text-12 text-red">Message cannot be more than 300 characters</span>}
                                </div>
                                <div className="flex mt-6">
                                    <RAMARUM_BUTTON text="submit" size="lg" ramarum_click={sendMessage} />
                                </div>
                            </form>
                        </div>
                        <div className="absolute formBubbles">
                            <svg width="336" height="416" viewBox="0 0 336 416" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="150.959" cy="265.639" r="141.644" transform="rotate(3.63645 150.959 265.639)" fill="url(#paint0_radial)" />
                                <circle opacity="0.4" cx="287.585" cy="48.8608" r="45.1255" transform="rotate(3.63645 287.585 48.8608)" fill="url(#paint1_radial)" />
                                <circle opacity="0.3" cx="293.274" cy="132.447" r="14.7529" transform="rotate(3.63645 293.274 132.447)" fill="url(#paint2_radial)" />
                                <defs>
                                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(150.959 123.995) rotate(90) scale(283.289)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                    <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(287.585 3.73529) rotate(90) scale(90.251)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                    <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(293.274 117.694) rotate(90) scale(29.5057)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>

                        </div>
                    </div>
                    <div className="lg:w-2/5 sm:mt-4 lg:pl-24 mt-14">
                        <div className="flex items-center">
                            <div>
                                <img src={phoneIcon} alt="Phone" />
                            </div>
                            <div className="pl-6">
                                <p className="font-semibold text-17 sm:text-24">Call</p>
                                <p className="text-17 sm:text-22">+91 99988 43960</p>
                            </div>
                        </div>
                        <div className="flex mt-14 items-center">
                            <div>
                                <img src={emailIcon} alt="Phone" />
                            </div>
                            <div className="pl-6">
                                <p className="font-semibold text-17 sm:text-24">Email:</p>
                                <p className="text-17 sm:text-22">supprt@ramarum.com</p>
                            </div>
                        </div>
                        <div className="flex mt-14 items-center">
                            <div>
                                <img src={timeIcon} alt="Phone" />
                            </div>
                            <div className="pl-6">
                                <p className="font-semibold text-17 sm:text-24">Hours:</p>
                                <p className="text-17 sm:text-22">Hours: 9am  - 6am (Mon - Fri)</p>
                            </div>
                        </div>
                        <div className="flex mt-14 items-start">
                            <div>
                                <img src={locationIcon} alt="Phone" />
                            </div>
                            <div className="pl-6">
                                <p className="font-semibold text-17 sm:text-24">Location:</p>
                                <p className="text-17 sm:text-22">Summerville, SC USA</p>
                            </div>
                        </div>
                        <div className="text-24 mt-14">
                            <SocialIconsDark title="Share This Ride On" />
                        </div>
                    </div>
                </div>
                <div className="mt-28">
                    <h2 className="font-bold text-17 sm:text-35">Frequently Asked Questions</h2>
                    {/* <p className="text-17">We Write The Best Things</p> */}

                    <div className="mt-9">
                        {accordianArray && <Accordian accordianData={accordianArray} />}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ContactusPage;