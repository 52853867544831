import { GET_SPONSERS, GET_SPONSERS_DETAILS, GET_MORE_SPONSERS, HIDE_SPONSERS_BUTTON } from '../actions/sponser-action';

const initialState = {
    sponsers: {},
    sponsersTableData: [],
    sponsersDetails: {},
    lastPage: false,
}

export default function sponser(state = initialState, action) {
    switch (action.type) {
        case GET_SPONSERS:
            return {
                ...state,
                sponsers: action.payload.sponsers,
                sponsersTableData: action.payload.sponsers.tableData,
                lastPage: action.payload.lastPage
            }
        case GET_MORE_SPONSERS:
            return {
                ...state,
                sponsers: action.payload,
                sponsersTableData: [...state.sponsersTableData, ...action.payload.tableData]
            }
        case GET_SPONSERS_DETAILS:
            return {
                ...state,
                sponsersDetails: action.payload
            }
        case HIDE_SPONSERS_BUTTON:
            return {
                ...state, 
                lastPage: action.payload
            }
        default:
            return state;
    }
}