import React, { useEffect } from 'react';
import pressImg from '../../assets/images/press.png'
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import { useSelector, useDispatch } from 'react-redux';
import { getPressNews, getMorePressNews } from '../../store/actions/press-action';
import { useHistory } from "react-router-dom";
import moment from 'moment';

function PressPage(props) {
    
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPressNews(0))
    }, [dispatch]);

    const news = useSelector((state) => {
        return state.press.newsTableData;
    });

    const newsObj = useSelector((state) => {
        return state.press.news;
    });

    const hideLoadMore = useSelector((state) => {
        return state.press.lastPage;
    });

    const loadMore = () => {
        dispatch(getMorePressNews(newsObj.nextPageStart))
    }

    const pressDetails = id => {
        history.push(`/press/${id}`);
    }

    return (
        <div className="mb-16">
            <div className="relative">
                <img className="object-cover w-full max-h-425" alt="Press" src={pressImg} />
                <div className="absolute top-5 sm:top-16 left-5 sm:left-24">
                    <h1 className="font-bold text-20 sm:text-35">Press</h1>
                    <p className="text-12 sm:text-17">We Write The Best Things</p>
                </div>
            </div>
            <div className="px-5 lg:px-24 mt-14">
                {news && news.map((item, i) => (
                    <div className="mt-16 cursor-pointer bg-white shadow rounded p-6" onClick={() => pressDetails(item.id)} key={i}>
                        <div className="static sm:flex">
                            <div className="w-full lg:w-2/5 xl:w-1/5">
                                <img className="rounded h-40 w-full" src={item?.documents && item.documents[0]?.display_files?.press_img?.square_url} alt="Press" />
                            </div>
                            <div className="w-full lg:w-3/5  lg:pl-7 md:pl-7 xl:w-4/5 pt-2">
                                <h2 className="font-semibold text-18">{item.title}</h2>
                                <p className="text-darkgrey text-12 mt-4">{moment(item.created).format('LLL')}</p>
                                <p className="text-13 mt-4 text-justify" dangerouslySetInnerHTML={{ __html: item?.press_content?.slice(0,200)}}></p>
                                <p className="text-14 opacity-40 mt-4">{item.press_name}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-14 flex justify-center">
                {!hideLoadMore && <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore}/>}
            </div>
        </div>
    );
};

export default PressPage;