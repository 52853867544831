import qs from 'qs';
import axios from 'axios';
import { PRESS, PRESS_DETAILS } from '../../constants';

export const GET_PRESS_NEWS = 'GET_PRESS_NEWS';
export const GET_MORE_PRESS_NEWS = 'GET_MORE_PRESS_NEWS';
export const GET_PRESS_DETAILS = 'GET_PRESS_DETAILS';
export const HIDE_NEWS_BUTTON = 'HIDE_NEWS_BUTTON';

export const getPressNews = pageNo => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: PRESS,
            data: qs.stringify({
                'start': pageNo,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if(response?.data?.result?.responce?.Press?.tableData?.length !== 0) {
            dispatch({
                type: GET_PRESS_NEWS,
                payload: response.data.result.responce.Press
            })
        }else {
            dispatch({
                type: HIDE_NEWS_BUTTON,
                payload: true
            })
        }

    } catch (error) {

    }
}

export const getMorePressNews = pageNo => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: PRESS,
            data: qs.stringify({
                'start': pageNo,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if(response?.data?.result?.responce?.Press?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_PRESS_NEWS,
                payload: response.data.result.responce.Press
            })
        }else {
            dispatch({
                type: HIDE_NEWS_BUTTON,
                payload: true
            })
        }

    } catch (error) {

    }
}

export const getPressDetails = id => async dispatch => {
    try {
        const response = await axios.get(`${PRESS_DETAILS}/${id}`);
        dispatch({
            type: GET_PRESS_DETAILS,
            payload: response.data.result.responce.Press
        })
    }catch (error) {

    }
}