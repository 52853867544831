import React from "react";
import RAMARUM_BUTTON from '../Ramarum-Button';
import moment from 'moment';

function SaleCard(props) {
    return (
        // className="bg-white w-40 h-56 min-w-40 sm:h-96 sm:w-64 sm:min-w-64 shadow rounded mx-auto cursor-pointer mt-4"
        <div className="bg-white rjw-card h-56 sm:h-96 shadow rounded mx-auto cursor-pointer mt-4" onClick={props.saleClick}>
            <div className="h-1/2">
                <img className="h-full object-cover rounded rounded-b-lg p-1.5 sm:p-0 w-full" src={props.displayImage} alt="Sponcered" />
            </div>
            <div className="h-1/2 relative">
                <div className="absolute bottom-0 w-full p-2 sm:p-4">
                    <div className="text-12 sm:text-16 leading-4 sm:leading-6 max-line3">
                        <p className="font-medium">{props.text}</p>
                    </div>
                    <p className="text-12 font-normal sm:font-bold sm:text-20 pt-1 sm:pt-2.5">${props.amount && props.amount.toFixed(2)}</p>
                    <div className="flex justify-between items-baseline pt-1 sm:pt-3">
                        <div className="pt-2.5 hidden sm:block">
                            <RAMARUM_BUTTON
                                btnType={props.btnType}
                                text={props.btnText}
                                size="sm" />
                        </div>
                        <div className="pt-2.5 block sm:hidden">
                            <RAMARUM_BUTTON
                                btnType={props.btnType}
                                text={props.btnText}
                                size="xs" />
                        </div>
                        <p className="text-9 sm:text-13">{moment(props.timestamp).fromNow()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaleCard;