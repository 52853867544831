import MOBILE_DOWNLOAD_LINK from '../Download-Links';
import dualMobile from '../../assets/images/mobile/dualmobiles.png';

function MobileAppAd(props) {
    return (
        <div className="1/3 mt-9 bg-lightYellow pt-8 px-9 rounded">
            <p className="text-24 font-bold">To Get This Ride Download Our App Now</p>
            <div className="flex space-x-5 mt-5">
                <MOBILE_DOWNLOAD_LINK />
            </div>
            <div className="mx-auto h-64 mt-14">
                <img className="object-contain h-full w-full" alt="Mobile" src={dualMobile} />
            </div>
        </div>
    );
};

export default MobileAppAd;