
import axios from 'axios';
import { HOME } from '../../constants';

export const GET_HOME_DATA = 'GET_HOME_DATA';

export const getHomeData = () => async dispatch => {
    try {
        const response = await axios.get(HOME);
        dispatch({
            type: GET_HOME_DATA,
            payload: {
                ...response.data.result.responce
            }
        })
    } catch (error) {

    }
}