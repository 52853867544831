
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import SocialIconsDark from '../../components/Social-Dark';
import { useSelector, useDispatch } from 'react-redux';
import { getPressDetails } from '../../store/actions/press-action';
import moment from 'moment'

function PressDetailsPage(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    
    useEffect(() => {
        dispatch(getPressDetails(id))
    }, [dispatch, id]);

    const newsDetails = useSelector((state) => {
        return state.press.newsDetails;
    });

    return (
        <div className="md:container mx-auto md:px-10 pb-14">
            <div className="mt-6 sm:mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                <p>Home &gt; <Link to="/press">Press</Link> &gt; {newsDetails.title}</p>
            </div>
            <div className="mt-6 sm:mt-9">
                <Carousel
                    autoPlay={false}
                    infiniteLoop={true}
                    swipeable={true}
                    dynamicHeight={true}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={false}>
                    {newsDetails?.documents && <img className="md:rounded" alt={newsDetails?.documents[0]?.title} src={newsDetails?.documents && newsDetails.documents[0]?.display_files?.press_img?.main_url} />}
                </Carousel>
                <div className="px-4 sm:px-0">
                    <div className="mt-8">
                        <h1 className="font-bold text-15 sm:text-31">{newsDetails.title}</h1>
                        <p className="opacity-50 text-15 sm:text-20 font-medium">Posted {moment(newsDetails?.created).fromNow()}</p>
                        <p className="mt-8 text-15 sm:text-20 text-justify" dangerouslySetInnerHTML={{ __html: newsDetails.press_content }}></p>

                        <div className="mt-2 flex lg:block overflow-x-scroll lg:overflow-x-hidden">
                            {newsDetails?.blog_tag && newsDetails.blog_tag.map((item, i) => (<span key={i} className="text-12 sm:text-14 black-border rounded-25 px-6 py-3 mr-5 whitespace-nowrap inline-block mt-3 sm:mt-4 cursor-pointer">{item}</span>))}
                        </div>
                        <div>
                            <SocialIconsDark title="Share This Ride On" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PressDetailsPage;