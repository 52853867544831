export const DOMAIN = "https://www.owner.ramarum.com/webapi";
// export const DOMAIN = "http://192.168.1.200/ramarum/webapi";
// export const WEB_DOMAIN = "https://www.web.ramarum.com/";


export const HOME = DOMAIN + "/";
export const RIDES = DOMAIN + "/posts/getRides";
export const RIDES_DETAILS = DOMAIN + "/posts/view";
export const JOBS = DOMAIN + "/posts/getJobs";
export const JOB_DETAILS = DOMAIN + "/posts/view";
export const BLOGS = DOMAIN + "/blogs/getBlogs";
export const BLOG_DETAILS = DOMAIN + "/blogs/view";
export const PRESS = DOMAIN + "/Press/getPress";
export const PRESS_DETAILS = DOMAIN + "/press/view";
export const FAQS = DOMAIN + "/faqs/getFaqs";
export const CONTACT_US = DOMAIN + "/contactus";
export const SUBSCRIBE = DOMAIN + "/subscribe";
export const SPONSORS = DOMAIN + "/sponsors/getSponsors";
export const SPONSOR_DETAILS = DOMAIN + "/sponsors/view";
export const INVESTORS = DOMAIN + "/investors/getInvestors";
export const INVESTOR_DETAILS = DOMAIN + "/investors/view";
export const SPONSOR_ADS = DOMAIN + "/posts/getSponsoradds";
export const POST_MASTER = DOMAIN + "/master/getPostMaster";
export const ADVERTISE_WITHUS = DOMAIN + "/advertisewithus";
export const HOW_ITS_WORK = DOMAIN + "/howitswork";

