import React from "react";

function RAMARUM_BUTTON(props) {
    return (
        <React.Fragment>
            {props.size === 'lg' &&
                <button
                    className="px-11 py-3 text-18 btn-primary rounded-full font-semibold relative z-10"
                    onClick={props.ramarum_click}>{props.text} {props.icon && <span className="absolute right-4 top-1/2 transform -translate-y-1/2"><img className="pl-12" alt="Subscribe" src={props.icon} /></span>}
                </button>
            }
            {props.size === 'md' &&
                <button
                    className={`px-7 py-3 text-16 z-10 ${props.btnType === 'outline' ? 'border border-darkYellow' : 'btn-primary'} rounded-full font-medium`}
                    onClick={props.ramarum_click}>{props.text}
                </button>
            }
            {props.size === 'sm' &&
                <button
                    className={`px-5 py-1 text-16 z-10 ${props.btnType === 'outline' ? 'border border-darkYellow' : 'btn-primary'} rounded-full font-medium`}
                    onClick={props.ramarum_click}>{props.text}
                </button>
            }
            {props.size === 'xs' &&
                <button
                    className={`px-3 py-1 text-9 z-10 ${props.btnType === 'outline' ? 'border border-darkYellow' : 'btn-primary'} rounded-full font-medium`}
                    onClick={props.ramarum_click}>{props.text}
                </button>
            }
        </React.Fragment>
    );
};

export default RAMARUM_BUTTON;