
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import SocialIconsDark from '../../components/Social-Dark';
import { useSelector, useDispatch } from 'react-redux';
import { getInvestorsDetails } from '../../store/actions/investor-action';
import moment from 'moment'

function InvestorDetailsPage(props) {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getInvestorsDetails(id))
    }, [dispatch, id]);

    const investorDetails = useSelector((state) => {
        // return state.blogs.investorDetails;
        return state.investors.investorsDetails;
    });

    return (
        <div className="md:container mx-auto md:px-10 pb-14">
            <div className="mt-6 sm:mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                <p>Home &gt; <Link to="/investor">Investor</Link> &gt; {investorDetails?.title}</p>
            </div>
            <div className="mt-6 sm:mt-9">
                <div className="px-4 sm:px-0">
                    <div className="mt-8">
                        <h1 className="font-bold text-15 sm:text-31">{investorDetails?.title}</h1>
                        <p className="opacity-50 text-15 sm:text-20 font-medium">Posted {moment(investorDetails?.created).fromNow()}</p>
                        <p className="mt-8 text-15 sm:text-20" dangerouslySetInnerHTML={{ __html: investorDetails?.content }}></p>
                        <div>
                            <SocialIconsDark title="Share This Ride On" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvestorDetailsPage;