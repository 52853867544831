import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import track from '../../assets/images/track.svg';
import track_mobile from '../../assets/images/track_mobile.svg';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import SaleCard from '../../components/Sale/Sale-Card'
import JobCard from '../../components/Job/Job-Card'
import frame from '../../assets/images/mobile/Frame.svg';
import MOBILE_DOWNLOAD_LINK from '../../components/Download-Links';
import OurPartners from '../../components/OurPartners/OurPartners';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Rating from 'react-rating';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getHomeData } from '../../store/actions/home-action';
import * as _ from 'lodash';
import './Home.css';
import testmania from '../../assets/images/social/testmania.svg';
import moment from 'moment';


let keyframe = {};

function HomePage(props) {

    let history = useHistory();
    const dispatch = useDispatch();

    window.onresize = _.debounce(() => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }, 100)

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const [selectedMobileAppCard, setSelectedMobileAppCard] = useState({
        id: 0,
        image: "https://www.owner.ramarum.com/assets/images/home_mb.jpg"
        // image: "https://wiki.albany.edu/download/attachments/105322457/Streamlabs1.jpg?version=1&modificationDate=1607295218000&api=v2"
    })

    const selectAppCard = idx => {
        setSelectedMobileAppCard({
            ...selectedMobileAppCard,
            id: idx
        })
    }

    useEffect(() => {
        dispatch(getHomeData())
    }, [dispatch]);

    const homeData = useSelector((state) => {
        return state.home;
    });

    function getBlogs(blogs) {
        if (blogs) {
            const arr = [...blogs];
            return arr.splice(0, 3);
        }
    }

    function testimonialcard(testimonial, i) {
        return <div className="h-full pb-16" key={i}>
            <div className="shadow w-96 min-w-96 p-6 z-10 text-left mx-auto h-full rounded">
                <Rating
                    className="text-27 text-darkYellow"
                    readonly
                    initialRating={testimonial.rating}
                    emptySymbol={<p>&#9734;</p>}
                    fullSymbol={<p>&#9733;</p>}
                    fractions={2}
                />
                <p className="text-15 mt-4">{testimonial.testimonial_text?.slice(0, 160)}{testimonial.testimonial_text?.length > 160 && '....'}</p>
                {/* <p className="mt-4 font-semibold text-15 sm:text-18">{testimonial.name}</p> */}
                {/* <p className="text-13 sm:text-15">{testimonial.position}</p> */}
                <div className="flex justify-between">
                    <div>
                        <p className="mt-4 font-semibold text-15 sm:text-18">{testimonial.name}</p>
                        <p className="text-13 sm:text-15">{testimonial.position}</p>
                    </div>

                    <div className="h-12 w-12 bg-no-repeat self-end" style={{ backgroundImage: `url(${testmania})` }}></div>
                </div>
            </div>
        </div>
    }

    function getTestimonials(dimensions, testimonials) {
        let card = '';
        let array = [];
        for (let i = 0; i < testimonials?.length; i++) {
            if (dimensions.width > + 1536) {
                card = <div className="flex justify-center space-x-16 mt-10" key={i}>
                    {testimonialcard(testimonials[i])}
                    {testimonials[i + 1] && testimonialcard(testimonials[i + 1])}
                    {testimonials[i + 2] && testimonialcard(testimonials[i + 2])}
                </div>
                i = i + 2;
            } else if (dimensions.width > 1024) {
                card = <div className="flex justify-center space-x-16 mt-10" key={i}>
                    {testimonialcard(testimonials[i])}
                    {testimonials[i + 1] && testimonialcard(testimonials[i + 1])}
                </div>
                i = i + 1;
            } else if (dimensions.width <= 1024) {
                card = testimonialcard(testimonials[i], i)
            }
            array.push(card)
        }
        return array;
    }

    function saleClick(item) {
        history.push(`/rides/${item.id}`);
    }

    function jobClick(item) {
        history.push(`/jobs/${item.id}`);
    }

    function blogClick(item) {
        history.push(`/blog/${item.id}`);
    }

    function sponsorAdsClick(item) {
        history.push(`/sponsorads/${item.id}`);
    }

    function showScrollAnimation(data) {
        return (256 * data?.length) >= dimensions.width;
    }

    const style = document.createElement('style');
    style.type = 'text/css';

    document.getElementsByTagName('head')[0].appendChild(style);


    const dropzoneStyle = (data, str) => {
        if ((256 * data?.length) >= dimensions.width) {
            keyframe[str] = `
                @keyframes ${str} {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(calc(-256px * A_DYNAMIC_VALUE));
                    }
                }`;
            style.innerHTML += keyframe[str].replace(/A_DYNAMIC_VALUE/g, data?.length);
            return {
                position: `absolute`,
                width: `${256 * data?.length * 2}px`,
                top: `0px`,
                left: `0px`,
                overflow: `visible`,
                whiteSpace: `break-spaces`,
                animation: `${str} ${(256 * data?.length) / 50}s linear infinite`
            }
        }
    };

    const bannerReadMore = url => {
        if (url) {
            window.location.href = url;
        }
    }

    return (
        <div>
            {/* *********************************************** Caroudal ********************************************** */}
            <header className="relative">
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    swipeable={true}
                    dynamicHeight={true}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}>
                    {homeData?.Homebanners?.tableData && homeData.Homebanners.tableData.map((item, i) => (
                        <div key={i}>
                            <img className="height-full object-cover" src={item?.documents && item.documents[0]?.display_files?.banner_img?.main_url} alt="Home Background" />
                            <div className="backdrop-filter backdrop-blur-md top-0 left-0 absolute w-full height-full">
                                <div className="bg-overlay text-center w-full height-full">
                                    <h1 className="text-white font-bold text-34 md:text-54 lg:pt-24 pt-16 px-9 sm:px-0">{item.title}</h1>
                                    <h2 className="text-white font-semibold text-16 md:text-24 mt-5 px-16 sm:px-0">{item.subtitle}</h2>
                                    <div className="mt-9 flex justify-center z-10 mb-24">
                                        <RAMARUM_BUTTON text="Learn More" size="lg" ramarum_click={() => bannerReadMore(item.button_url)} />

                                    </div>
                                </div>
                            </div>
                            {homeData?.Homebanners && <div className="hidden lg:flex">
                                <img className="track absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/5 h-3/5 z-0" src={track} alt="track" />
                                {/* <img className="track-mobile absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 h-2/5 w-3/5 sm:flex lg:hidden" src={track_mobile} alt="track" /> */}
                            </div>}
                            
                            {homeData?.Homebanners && <div className="sm:flex lg:hidden">
                                {/* <img className="track absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/5 h-3/5 z-0" src={track} alt="track" /> */}
                                <img className="track-mobile absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 h-2/5 w-3/5" src={track_mobile} alt="track" />
                            </div>}

                        </div>

                    ))}
                    
                </Carousel>
                {/* {homeData?.Homebanners && <div>
                    <img className="track absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 z-0" src={track} alt="track" />
                    <img className="track-mobile absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 h-1/2 w-4/5" src={track_mobile} alt="track" />
                </div>} */}
            </header>

            {/* *********************************************** Sponcered Ads ********************************************** */}
            <div>
                <div className="mt-5 sm:mt-10 relative">
                    <h1 className="text-center font-semibold sm:font-bold text-20 md:text-35">Sponsored Ads</h1>
                    <div className="absolute right-3 bottom-0">
                        <Link to="/ads" className="font-semibold text-13 md:text-22 text-link">View All</Link>
                    </div>
                </div>

                <div className="mt-5  sm:mt-14 card-slideshow">
                    <div className="sponseredAds flex space-x-4" style={dropzoneStyle(homeData?.Sponsoradds?.tableData, 'ads')}>
                        {homeData?.Sponsoradds?.tableData && homeData.Sponsoradds.tableData.map((item, i) => (
                            <div key={i}>
                                <SaleCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    title={item?.post_title}
                                    // text={`${item?.address_full.slice(0, 20)}${item?.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    amount={item?.your_amount}
                                    btnType="full"
                                    btnText={item?.sponsortype_id && item?.sponsortype_id[0].text}
                                    timestamp={item?.created}
                                    saleClick={() => sponsorAdsClick(item)}
                                />
                            </div>
                        ))}
                        {showScrollAnimation(homeData?.Sponsoradds?.tableData) && homeData?.Sponsoradds?.tableData && homeData.Sponsoradds.tableData.map((item, i) => (
                            <div key={i}>
                                <SaleCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    title={item?.post_title}
                                    // text={`${item?.address_full.slice(0, 20)}${item?.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    amount={item?.your_amount}
                                    btnType="full"
                                    btnText={item?.sponsortype_id && item?.sponsortype_id[0].text}
                                    timestamp={item?.created}
                                    saleClick={() => sponsorAdsClick(item)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* *********************************************** Find Rides ********************************************** */}
            <div>
                <div className="mt-7 sm:mt-20 relative">
                    <div>
                        <h1 className="text-center font-semibold sm:font-bold text-20 md:text-35">Find Rides & Ride Partners</h1>
                        <p className="text-center hidden sm:block">Lets get rides in quick steps.</p>
                    </div>
                    <div className="absolute right-3 bottom-0">
                        <Link to="/rides" className="font-semibold text-13 md:text-22 text-link">View All</Link>
                    </div>
                </div>

                <div className="mt-8 sm:mt-14 card-slideshow">
                    <div className="flex space-x-4" style={dropzoneStyle(homeData?.Rides?.tableData, 'ride')}>
                        {homeData?.Rides?.tableData && homeData.Rides.tableData.map((item, i) => (
                            <div key={i}>
                                <SaleCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    amount={item.your_amount}
                                    btnType={(item.post_type === 'RS' && 'full') || (item.post_type === 'RP' && 'outline')}
                                    btnText={(item.post_type === 'RS' && <span>Wanted</span>) || (item.post_type === 'RP' && <span>Offering</span>)}
                                    timestamp={item?.created}
                                    saleClick={() => saleClick(item)}
                                />
                            </div>
                        ))}
                        {showScrollAnimation(homeData?.Rides?.tableData) && homeData?.Rides?.tableData && homeData.Rides.tableData.map((item, i) => (
                            <div key={i}>
                                <SaleCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    amount={item.your_amount}
                                    btnType={(item.post_type === 'RS' && 'full') || (item.post_type === 'RP' && 'outline')}
                                    btnText={(item.post_type === 'RS' && <span>Wanted</span>) || (item.post_type === 'RP' && <span>Offering</span>)}
                                    timestamp={item?.created}
                                    saleClick={() => saleClick(item)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* *********************************************** Find Jobs ********************************************** */}
            <div>
                <div className="mt-7 sm:mt-20 relative">
                    <div>
                        <h1 className="text-center font-semibold sm:font-bold text-20 md:text-35">Find jobs & Employees</h1>
                        <p className="text-center hidden sm:block">Easy to get job and posting a vacancies.</p>
                    </div>
                    <div className="absolute right-3 bottom-0">
                        <Link to="/jobs" className="font-semibold text-13 md:text-22 text-link">View All</Link>
                    </div>
                </div>

                <div className="mt-8 sm:mt-14 card-slideshow">
                    <div className="flex space-x-4" style={dropzoneStyle(homeData?.Jobs?.tableData, 'job')}>
                        {homeData?.Jobs?.tableData && homeData.Jobs.tableData.map((item, i) => (
                            <div key={i} onClick={() => jobClick(item)}>
                                <JobCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    title={item?.businesstype_id && item?.businesstype_id[0].text}
                                    // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    type={item?.amount_type && item?.amount_type[0].text}
                                    amount={item?.your_amount}
                                    timestamp={item?.created}
                                />
                            </div>
                        ))}
                        {showScrollAnimation(homeData?.Jobs?.tableData) && homeData?.Jobs?.tableData && homeData.Jobs.tableData.map((item, i) => (
                            <div key={i} onClick={() => jobClick(item)}>
                                <JobCard
                                    displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                    title={item?.businesstype_id && item?.businesstype_id[0].text}
                                    // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                    text={item.address_full}
                                    type={item?.amount_type && item?.amount_type[0].text}
                                    amount={item?.your_amount}
                                    timestamp={item?.created}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* *********************************************** Mobile Application ********************************************** */}

            <div className="mt-0 sm:mt-32 bg-no-repeat bg-contain relative overflow-hidden mobileAppBg">
                <div className="pt-10">
                    <h1 className="text-center font-semibold sm:font-bold text-20 sm:text-35">Ramarum Mobile App</h1>
                </div>
                <div className="mt-4 sm:mt-12">
                    <div className="md:grid grid-cols-2 lg:grid-cols-3 place-items-center hidden">
                        <div className="mt-0 sm:mt-7">
                            <div className={`${selectedMobileAppCard.id === 0 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer`} onClick={() => selectAppCard(0)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 0 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 29.9141H25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 17.9141C18.2091 17.9141 20 16.1232 20 13.9141C20 11.7049 18.2091 9.91406 16 9.91406C13.7909 9.91406 12 11.7049 12 13.9141C12 16.1232 13.7909 17.9141 16 17.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26 13.9141C26 22.9141 16 29.9141 16 29.9141C16 29.9141 6 22.9141 6 13.9141C6 11.2619 7.05357 8.71836 8.92893 6.84299C10.8043 4.96763 13.3478 3.91406 16 3.91406C18.6522 3.91406 21.1957 4.96763 23.0711 6.84299C24.9464 8.71836 26 11.2619 26 13.9141V13.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">Convenient & Affordable</h4>
                                    <p className="text-14 mt-1.5">Let's make this your ride more affordable and get ride and share ride</p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 1 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(1)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 1 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.001 9.65503H5.00098C4.44869 9.65503 4.00098 10.1027 4.00098 10.655V26.655C4.00098 27.2073 4.44869 27.655 5.00098 27.655H27.001C27.5533 27.655 28.001 27.2073 28.001 26.655V10.655C28.001 10.1027 27.5533 9.65503 27.001 9.65503Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M21 13.655V7.65503C21 7.1246 20.7893 6.61589 20.4142 6.24082C20.0391 5.86574 19.5304 5.65503 19 5.65503H13C12.4696 5.65503 11.9609 5.86574 11.5858 6.24082C11.2107 6.61589 11 7.1246 11 7.65503V13.655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">More Earnings</h4>
                                    <p className="text-14 mt-1.5">For sharing a ride is  your 100% earning, No commission.</p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 2 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(2)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 2 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28 26.9141H4V6.91406" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 8.91406L16.0002 18.9141L12.0002 14.9141L4.00024 22.9141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 13.9141V8.91406H21.0002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">Get Top Ride Experience</h4>
                                    <p className="text-14 mt-1.5">Here you can find a car based on your requirement of journey.</p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 3 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(3)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 3 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0001 17.7731L22.2397 27.664C22.3698 27.7784 22.5273 27.8571 22.6969 27.8924C22.8665 27.9276 23.0424 27.9182 23.2073 27.865C23.3721 27.8119 23.5204 27.7168 23.6374 27.5891C23.7545 27.4614 23.8364 27.3055 23.8751 27.1367L28.5768 6.6201C28.6175 6.44272 28.6089 6.2576 28.5521 6.0847C28.4954 5.9118 28.3925 5.75768 28.2546 5.63893C28.1167 5.52019 27.949 5.44133 27.7695 5.41085C27.5901 5.38037 27.4058 5.39943 27.2364 5.46598L4.16682 14.529C3.96576 14.608 3.79569 14.75 3.68204 14.9337C3.5684 15.1174 3.5173 15.333 3.53638 15.5481C3.55547 15.7633 3.64372 15.9665 3.78792 16.1274C3.93213 16.2882 4.12453 16.398 4.33635 16.4404L11.0001 17.7731Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 17.7731L28.014 5.48523" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.6134 22.7128L12.7071 26.6191C12.5673 26.759 12.3891 26.8542 12.1951 26.8928C12.0011 26.9314 11.8 26.9116 11.6173 26.8359C11.4346 26.7602 11.2784 26.632 11.1685 26.4676C11.0587 26.3031 11 26.1098 11 25.912V17.7731" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold"> Direct Chat with user</h4>
                                    <p className="text-14 mt-1.5">Communication makes it clear about the ride to negotiate, budgets and vehicle details.</p>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:flex">
                            <div className="mobileFrame">
                                <img alt="Frame" src={frame} />
                                <img className="mobileImage" alt="Frame" src={selectedMobileAppCard.image} />
                            </div>

                        </div>
                        <div className="mt-0 sm:mt-7">
                            <div className={`${selectedMobileAppCard.id === 4 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer`} onClick={() => selectAppCard(4)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 4 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 29.9141H25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 17.9141C18.2091 17.9141 20 16.1232 20 13.9141C20 11.7049 18.2091 9.91406 16 9.91406C13.7909 9.91406 12 11.7049 12 13.9141C12 16.1232 13.7909 17.9141 16 17.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26 13.9141C26 22.9141 16 29.9141 16 29.9141C16 29.9141 6 22.9141 6 13.9141C6 11.2619 7.05357 8.71836 8.92893 6.84299C10.8043 4.96763 13.3478 3.91406 16 3.91406C18.6522 3.91406 21.1957 4.96763 23.0711 6.84299C24.9464 8.71836 26 11.2619 26 13.9141V13.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">Finding Jobs</h4>
                                    <p className="text-14 mt-1.5">Posting a resume is free of cost, even without posting your CV you can Explore jobs. </p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 5 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(5)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 5 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.001 9.65503H5.00098C4.44869 9.65503 4.00098 10.1027 4.00098 10.655V26.655C4.00098 27.2073 4.44869 27.655 5.00098 27.655H27.001C27.5533 27.655 28.001 27.2073 28.001 26.655V10.655C28.001 10.1027 27.5533 9.65503 27.001 9.65503Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M21 13.655V7.65503C21 7.1246 20.7893 6.61589 20.4142 6.24082C20.0391 5.86574 19.5304 5.65503 19 5.65503H13C12.4696 5.65503 11.9609 5.86574 11.5858 6.24082C11.2107 6.61589 11 7.1246 11 7.65503V13.655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">Creating CV</h4>
                                    <p className="text-14 mt-1.5">Create your CV to impress your employer in quick and very easy steps.</p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 6 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(6)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 6 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28 26.9141H4V6.91406" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 8.91406L16.0002 18.9141L12.0002 14.9141L4.00024 22.9141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 13.9141V8.91406H21.0002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold"> Employer gateway</h4>
                                    <p className="text-14 mt-1.5">No worry to find the right candidate for your business, post here your vacancy in easy steps.</p>
                                </div>
                            </div>
                            <div className={`${selectedMobileAppCard.id === 7 ? "bg-blue text-white" : "bg-white"} flex rounded w-96 items-center py-6 shadow cursor-pointer mt-6`} onClick={() => selectAppCard(7)}>
                                <div className="px-8">
                                    <svg className={`stroke-current ${selectedMobileAppCard.id === 7 ? "text-white" : "text-black"}`} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0001 17.7731L22.2397 27.664C22.3698 27.7784 22.5273 27.8571 22.6969 27.8924C22.8665 27.9276 23.0424 27.9182 23.2073 27.865C23.3721 27.8119 23.5204 27.7168 23.6374 27.5891C23.7545 27.4614 23.8364 27.3055 23.8751 27.1367L28.5768 6.6201C28.6175 6.44272 28.6089 6.2576 28.5521 6.0847C28.4954 5.9118 28.3925 5.75768 28.2546 5.63893C28.1167 5.52019 27.949 5.44133 27.7695 5.41085C27.5901 5.38037 27.4058 5.39943 27.2364 5.46598L4.16682 14.529C3.96576 14.608 3.79569 14.75 3.68204 14.9337C3.5684 15.1174 3.5173 15.333 3.53638 15.5481C3.55547 15.7633 3.64372 15.9665 3.78792 16.1274C3.93213 16.2882 4.12453 16.398 4.33635 16.4404L11.0001 17.7731Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 17.7731L28.014 5.48523" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.6134 22.7128L12.7071 26.6191C12.5673 26.759 12.3891 26.8542 12.1951 26.8928C12.0011 26.9314 11.8 26.9116 11.6173 26.8359C11.4346 26.7602 11.2784 26.632 11.1685 26.4676C11.0587 26.3031 11 26.1098 11 25.912V17.7731" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6">
                                    <h4 className="font-semibold">Stay Connected </h4>
                                    <p className="text-14 mt-1.5">Get notification on your mobile application  anywhere in the world. It makes you more comfortable.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Carousel className="block md:hidden"
                        autoPlay={false}
                        infiniteLoop={false}
                        swipeable={true}
                        dynamicHeight={true}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}>
                        <div className="mt-0 sm:mt-7 pb-10">
                            <div className="bg-white flex rounded w-96 items-center py-6 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 29.9141H25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 17.9141C18.2091 17.9141 20 16.1232 20 13.9141C20 11.7049 18.2091 9.91406 16 9.91406C13.7909 9.91406 12 11.7049 12 13.9141C12 16.1232 13.7909 17.9141 16 17.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26 13.9141C26 22.9141 16 29.9141 16 29.9141C16 29.9141 6 22.9141 6 13.9141C6 11.2619 7.05357 8.71836 8.92893 6.84299C10.8043 4.96763 13.3478 3.91406 16 3.91406C18.6522 3.91406 21.1957 4.96763 23.0711 6.84299C24.9464 8.71836 26 11.2619 26 13.9141V13.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Top Class Ride Experience</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.001 9.65503H5.00098C4.44869 9.65503 4.00098 10.1027 4.00098 10.655V26.655C4.00098 27.2073 4.44869 27.655 5.00098 27.655H27.001C27.5533 27.655 28.001 27.2073 28.001 26.655V10.655C28.001 10.1027 27.5533 9.65503 27.001 9.65503Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M21 13.655V7.65503C21 7.1246 20.7893 6.61589 20.4142 6.24082C20.0391 5.86574 19.5304 5.65503 19 5.65503H13C12.4696 5.65503 11.9609 5.86574 11.5858 6.24082C11.2107 6.61589 11 7.1246 11 7.65503V13.655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Best Jobs From Local Shops</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28 26.9141H4V6.91406" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 8.91406L16.0002 18.9141L12.0002 14.9141L4.00024 22.9141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 13.9141V8.91406H21.0002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">More Earnings</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0001 17.7731L22.2397 27.664C22.3698 27.7784 22.5273 27.8571 22.6969 27.8924C22.8665 27.9276 23.0424 27.9182 23.2073 27.865C23.3721 27.8119 23.5204 27.7168 23.6374 27.5891C23.7545 27.4614 23.8364 27.3055 23.8751 27.1367L28.5768 6.6201C28.6175 6.44272 28.6089 6.2576 28.5521 6.0847C28.4954 5.9118 28.3925 5.75768 28.2546 5.63893C28.1167 5.52019 27.949 5.44133 27.7695 5.41085C27.5901 5.38037 27.4058 5.39943 27.2364 5.46598L4.16682 14.529C3.96576 14.608 3.79569 14.75 3.68204 14.9337C3.5684 15.1174 3.5173 15.333 3.53638 15.5481C3.55547 15.7633 3.64372 15.9665 3.78792 16.1274C3.93213 16.2882 4.12453 16.398 4.33635 16.4404L11.0001 17.7731Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 17.7731L28.014 5.48523" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.6134 22.7128L12.7071 26.6191C12.5673 26.759 12.3891 26.8542 12.1951 26.8928C12.0011 26.9314 11.8 26.9116 11.6173 26.8359C11.4346 26.7602 11.2784 26.632 11.1685 26.4676C11.0587 26.3031 11 26.1098 11 25.912V17.7731" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Chat With Vendor</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>

                        </div>
                        <div className="mt-0 sm:mt-7 pb-10">
                            <div className="bg-white flex rounded w-96 items-center py-6 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 29.9141H25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 17.9141C18.2091 17.9141 20 16.1232 20 13.9141C20 11.7049 18.2091 9.91406 16 9.91406C13.7909 9.91406 12 11.7049 12 13.9141C12 16.1232 13.7909 17.9141 16 17.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26 13.9141C26 22.9141 16 29.9141 16 29.9141C16 29.9141 6 22.9141 6 13.9141C6 11.2619 7.05357 8.71836 8.92893 6.84299C10.8043 4.96763 13.3478 3.91406 16 3.91406C18.6522 3.91406 21.1957 4.96763 23.0711 6.84299C24.9464 8.71836 26 11.2619 26 13.9141V13.9141Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Top Class Ride Experience</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.001 9.65503H5.00098C4.44869 9.65503 4.00098 10.1027 4.00098 10.655V26.655C4.00098 27.2073 4.44869 27.655 5.00098 27.655H27.001C27.5533 27.655 28.001 27.2073 28.001 26.655V10.655C28.001 10.1027 27.5533 9.65503 27.001 9.65503Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M21 13.655V7.65503C21 7.1246 20.7893 6.61589 20.4142 6.24082C20.0391 5.86574 19.5304 5.65503 19 5.65503H13C12.4696 5.65503 11.9609 5.86574 11.5858 6.24082C11.2107 6.61589 11 7.1246 11 7.65503V13.655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Best Jobs From Local Shops</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28 26.9141H4V6.91406" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 8.91406L16.0002 18.9141L12.0002 14.9141L4.00024 22.9141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.0002 13.9141V8.91406H21.0002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">More Earnings</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>
                            <div className="bg-white flex rounded w-96 items-center py-6 mt-4 mx-auto">
                                <div className="px-8">
                                    <svg className="stroke-current text-black" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0001 17.7731L22.2397 27.664C22.3698 27.7784 22.5273 27.8571 22.6969 27.8924C22.8665 27.9276 23.0424 27.9182 23.2073 27.865C23.3721 27.8119 23.5204 27.7168 23.6374 27.5891C23.7545 27.4614 23.8364 27.3055 23.8751 27.1367L28.5768 6.6201C28.6175 6.44272 28.6089 6.2576 28.5521 6.0847C28.4954 5.9118 28.3925 5.75768 28.2546 5.63893C28.1167 5.52019 27.949 5.44133 27.7695 5.41085C27.5901 5.38037 27.4058 5.39943 27.2364 5.46598L4.16682 14.529C3.96576 14.608 3.79569 14.75 3.68204 14.9337C3.5684 15.1174 3.5173 15.333 3.53638 15.5481C3.55547 15.7633 3.64372 15.9665 3.78792 16.1274C3.93213 16.2882 4.12453 16.398 4.33635 16.4404L11.0001 17.7731Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 17.7731L28.014 5.48523" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.6134 22.7128L12.7071 26.6191C12.5673 26.759 12.3891 26.8542 12.1951 26.8928C12.0011 26.9314 11.8 26.9116 11.6173 26.8359C11.4346 26.7602 11.2784 26.632 11.1685 26.4676C11.0587 26.3031 11 26.1098 11 25.912V17.7731" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="pr-6 text-left">
                                    <h4 className="font-semibold">Chat With Vendor</h4>
                                    <p className="text-12 mt-1.5">Let's make this your least expensive journey ever beautiful with earning.</p>
                                </div>
                            </div>

                        </div>
                    </Carousel>
                    <div className="flex justify-center space-x-5 mt-10 pb-20">
                        <MOBILE_DOWNLOAD_LINK />
                    </div>
                </div>
            </div>


            {/* *********************************************** Testimonials ********************************************** */}

            <div className="relative overflow-hidden">
                <div className="hidden sm:block absolute">
                    <svg width="103" height="412" viewBox="0 0 103 412" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="-65.4121" cy="270.285" r="141.644" fill="url(#paint0_radial)" />
                        <circle opacity="0.4" cx="57.1887" cy="45.2773" r="45.1255" fill="url(#paint1_radial)" />
                        <circle opacity="0.3" cx="57.1886" cy="129.032" r="14.7529" fill="url(#paint2_radial)" />
                        <defs>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-65.4121 128.641) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.1887 0.151855) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.1886 114.279) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div className="mt-5">
                    <h1 className="text-center font-semibold sm:font-bold text-20 sm:text-35">Testimonials</h1>
                </div>
                <Carousel 
                    autoPlay={false}
                    infiniteLoop={false}
                    swipeable={true}
                    // dynamicHeight={true}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}>
                    {getTestimonials(dimensions, homeData?.Testimonials?.tableData)}
                </Carousel>
            </div>

            {/* *********************************************** Our Partners ********************************************** */}

            <div className="mt-5">
                <OurPartners style={style} partners={homeData?.Partners?.tableData} />
            </div>

            {/* *********************************************** Blog ********************************************** */}

            <div className="mb-36 relative">
                <div className="hidden sm:block absolute right-0 top-n z-0">
                    <svg width="196" height="513" viewBox="0 0 196 513" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.2" cx="200.647" cy="311.821" r="141.644" transform="rotate(-43.8313 200.647 311.821)" fill="url(#paint0_radial)" />
                        <circle opacity="0.4" cx="133.263" cy="64.599" r="45.1255" transform="rotate(-43.8313 133.263 64.599)" fill="url(#paint1_radial)" />
                        <circle opacity="0.5" cx="191.266" cy="125.018" r="14.7529" transform="rotate(-43.8313 191.266 125.018)" fill="url(#paint2_radial)" />
                        <defs>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(200.647 170.177) rotate(90) scale(283.289)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.263 19.4735) rotate(90) scale(90.251)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                            <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(191.266 110.265) rotate(90) scale(29.5057)">
                                <stop stopColor="#FFE6B3" />
                                <stop offset="0.442708" stopColor="#FFD171" />
                                <stop offset="1" stopColor="#F7AC49" />
                            </radialGradient>
                        </defs>
                    </svg>

                </div>
                <div className="mt-7 sm:mt-28 relative">
                    <div>
                        <h1 className="text-center font-semibold sm:font-bold text-20 md:text-35">Blog</h1>
                        <p className="text-center hidden sm:block">Let's make this your least expensive journey ever.</p>
                    </div>
                    <div className="absolute right-3 bottom-0">
                        <Link to="/blog" className="font-semibold text-13 md:text-22 text-link">View All</Link>
                    </div>
                </div>
                <div className="xl:flex justify-center xl:space-x-16 mt-10">
                    {homeData?.Blogs?.tableData && getBlogs(homeData?.Blogs?.tableData).map((item, i) => (<div onClick={() => blogClick(item)} key={i} className="rounded shadow sm:w-96 sm:min-w-96 p-6 mt-5 mx-5">
                        <div className="">
                            <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${item.documents[0].display_files.blog_feature_img.square_url})` }}>

                            </div>
                        </div>
                        <div className="h-1/2">
                            <p className="font-semibold text-14 sm:text-18 mt-4" dangerouslySetInnerHTML={{ __html: item.title.slice(0, 80) }}></p>
                            <div className="flex justify-between mt-4">
                                <p className="font-medium text-14 sm:text-16 text-blue cursor-pointer">Continue Reading...</p>
                                {/* <p className="font-medium text-15 opacity-90">2d ago</p> */}
                                <p className="font-medium text-15 opacity-50"> {moment(item.created).fromNow()}</p>

                            </div>
                        </div>
                    </div>))}
                </div>
            </div>

        </div>
    );
};

export default HomePage;