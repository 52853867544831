// import React, { useState } from 'react';
import adv1 from '../assets/images/adv/adv1.jpg';
import adv2 from '../assets/images/adv/adv2.jpg';
import adv3 from '../assets/images/adv/adv3.svg';
import adv4 from '../assets/images/adv/adv4.svg';
import adv5 from '../assets/images/adv/adv5.svg';
import groupDots from '../assets/images/adv/groupDots.svg';
import advOption from '../assets/images/adv/advOption.png';
import aboutus from '../assets/images/Aboutus.jpg';
import ourstory from '../assets/images/Ourstory.jpg';
import qs from 'qs';
import axios from 'axios';
import { toast } from 'react-toastify';
import RAMARUM_BUTTON from './Ramarum-Button';
import { ADVERTISE_WITHUS } from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { getBlogs } from '../store/actions/blog-action';
import * as _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function LearnHowToMarket() {

      let history = useHistory();
const dispatch = useDispatch();
const [data, setData] = useState({
        search: '',
        category_id: '',
        blog_tag: ''
    });

    const postBlogData = useCallback(
        _.debounce(data => {
            dispatch(getBlogs(data, '', '', 0))
        }, 1000),
        []
    );

     useEffect(() => {
        postBlogData(data)
    }, [postBlogData,data]);

    const blogs = useSelector((state) => {
        return state.blogs.blogsTableData;
    });
      const blogDetails = id => {
        history.push(`/blog/${id}`);
    }
    return (
        <div>
            <div className="text-center self-center lg:w-1/2 pl-5 pr-5 block mx-auto lg:pt-10">
                <h1 className="font-bold text-20 sm:text-35">Let’s see how we marking your business</h1>
            </div>
           {/*  <div className="flex px-5 mt-14 flex-col-reverse lg:flex-row justify-center">
                <div className="">
                    <div>
                        <div className="grid grid-cols- xl:grid-cols-3 gap-8">
                            <div className="cursor-pointer">
                                <div className="rounded shadow sm:w-96 sm:min-w-96 p-6 mx-auto" >
                                    <div className="">
                                        <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${adv3})` }}>
                                        </div>
                                    </div>
                                    <div className="h-4/5">
                                        <p className="font-semibold text-13 sm:text-18 mt-3">What Makes Telehealth Significant for Seniors in Modern Times ?</p>
                                        <p className="text-11 sm:text-13 mt-4">There are significant benefits of telehealth for seniors. In an era when it is advised to practice social distancing, telehealth can indeed be an effective option upon providing the scope of virtual.... </p>
                                        <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">12 December 2020 9:00pm</p>
                                        <p className="text-10 sm:text-14 opacity-40 mt-3">By Ramarum media team</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <div className="rounded shadow sm:w-96 sm:min-w-96 p-6 mx-auto" >
                                    <div className="">
                                        <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${adv4})` }}>
                                        </div>
                                    </div>
                                    <div className="h-4/5">
                                        <p className="font-semibold text-13 sm:text-18 mt-3">What Makes Telehealth Significant for Seniors in Modern Times ?</p>
                                        <p className="text-11 sm:text-13 mt-4">There are significant benefits of telehealth for seniors. In an era when it is advised to practice social distancing, telehealth can indeed be an effective option upon providing the scope of virtual.... </p>
                                        <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">12 December 2020 9:00pm</p>
                                        <p className="text-10 sm:text-14 opacity-40 mt-3">By Ramarum media team</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <div className="rounded shadow sm:w-96 sm:min-w-96 p-6 mx-auto" >
                                    <div className="">
                                        <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${adv5})` }}>
                                        </div>
                                    </div>
                                    <div className="h-4/5">
                                        <p className="font-semibold text-13 sm:text-18 mt-3">What Makes Telehealth Significant for Seniors in Modern Times ?</p>
                                        <p className="text-11 sm:text-13 mt-4">There are significant benefits of telehealth for seniors. In an era when it is advised to practice social distancing, telehealth can indeed be an effective option upon providing the scope of virtual.... </p>
                                        <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">12 December 2020 9:00pm</p>
                                        <p className="text-10 sm:text-14 opacity-40 mt-3">By Ramarum media team</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

             <div className="grid grid-cols- xl:grid-cols-3 gap-8 mt-10">
                                {blogs && blogs.slice(0, 3).map((item, i) => (
                                    <div key={i} className="cursor-pointer">
                                        <div className="rounded shadow sm:w-96 sm:min-w-96 p-6 mx-auto min-h-425 max-h-425" onClick={() => blogDetails(item.id)}>
                                            <div className="">
                                                <div className="h-40 rounded rounded-b-lg bg-no-repeat bg-cover" style={{ backgroundImage: `url(${item.documents[0].display_files.blog_feature_img.square_url})` }}>

                                                </div>
                                            </div>
                                            <div className="h-1/2">
                                                <p className="font-semibold text-13 sm:text-18 mt-3">{item.title}</p>
                                                <p className="text-11 sm:text-13 mt-4 text-justify" dangerouslySetInnerHTML={{ __html: item.content.length > 150 ? `${item.content.slice(0, 150)}....` : item.content }}></p>
                                                <p className="font-medium text-11 sm:text-12 mt-4 text-darkgrey">{moment(item.created).format('LLL')}</p>
                                                <p className="text-10 sm:text-14 opacity-40 mt-3">{item?.createdby?.first_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
        </div >
    );
}

function AdvertiseOption(props) {
    return (
        <div>
            <div className="text-center self-center block mx-auto">
                <h1 className="font-bold text-20 sm:text-35">Where Your Ads </h1>
            </div>
            <div className="flex px-5 mt-5 flex-col-reverse lg:flex-row justify-center mt-10">
                <div>
                    <div className="grid grid-cols-3 xl:grid-cols-5 sm:gap-4 lg:gap-8">
                        <div className="cursor-pointer">
                            <img src={advOption} alt="Option" className="lg:w-40 lg:h-40" />
                            <p className="font-semibold text-13 sm:text-18 mt-3 mb-3 text-center sm:mr-12">Website</p>
                        </div>
                        <div className="cursor-pointer">
                            <img src={advOption} alt="Option" className="lg:w-40 lg:h-40" />
                            <p className="font-semibold text-13 sm:text-18 mt-3 mb-3 text-center">Mobile application</p>
                        </div>
                        <div className="cursor-pointer">
                            <img src={advOption} alt="Option" className="lg:w-40 lg:h-40" />
                            <p className="font-semibold text-13 sm:text-18 mt-3 mb-3 text-center">Social Media</p>
                        </div>
                        <div className="cursor-pointer">
                            <img src={advOption} alt="Option" className="lg:w-40 lg:h-40" />
                            <p className="font-semibold text-13 sm:text-18 mt-3 mb-3 text-center">Blogs & Members</p>
                        </div>
                        <div className="cursor-pointer">
                            <img src={advOption} alt="Option" className="lg:w-40 lg:h-40" />
                            <p className="font-semibold text-13 sm:text-18 mt-3 mb-3 text-center">Digital Campaign</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


function ReadyToTryOurAds(props) {
    // const dispatch = useDispatch();
    const defaultValues = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    };
    const defaultValidateValues = {
        name: false,
        email: false,
        phone: false,
        subject: false,
        message: false
    };
    const [data, setData] = useState({ ...defaultValues });
    const [dataValidate, setDataValidate] = useState({ ...defaultValidateValues });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };


    const submitAds = async e => {
        e.preventDefault();
        try {
            if (validateMessage()) {
                const response = await axios({
                    method: 'post',
                    url: ADVERTISE_WITHUS,
                    data: qs.stringify({ ...data }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (response.data.code === 200) {
                    notifySuccess(response.data.result.success);
                    setData({ ...defaultValues });
                } else {
                    notifyError(response.data.result.error);
                }
            }
        } catch (error) {
            notifyError("There's some technical error. Please try again after some time.");
        }
    }

    const validateMessage = () => {
        let isValid = true;
        let obj = { ...defaultValidateValues };
        if (data.name?.length > 60 || data.name?.length === 0) {
            obj.name = true;
            isValid = false;
        }
        if (!data.email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            obj.email = true;
            isValid = false;
        }
        if (!data.phone || data.phone?.match(/\d/g)?.length !== 10) {
            obj.phone = true;
            isValid = false;
        }
        if (data.subject?.length > 50 || data.subject?.length === 0) {
            obj.subject = true;
            isValid = false;
        }
        if (data.message?.length > 300 || data.message?.length === 0) {
            obj.message = true;
            isValid = false;
        }
        setDataValidate({ ...obj });
        return isValid;
    }

    const notifySuccess = (msg) => {
        // Calling toast method by passing string
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    const notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });

    };

    return (
        <div>
            <div className="text-center self-center  block mx-auto">
                <h1 className="font-bold text-20 sm:text-35">Submit your Ads here</h1>
                <p className="font-medium text-12 sm:text-17">We will contact each other shortly in business hours.</p>
            </div>
            <div className="mt-7 mt-10 container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
                <div className="lg:w-1/2 relative">
                    <form>
                        <div>
                            <label className="block text-14">Name:</label>
                            <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="text" value={data.name} name="name" onChange={handleChange} />
                            {dataValidate.name && <span className="text-12 text-red">Invalid Name</span>}
                        </div>
                        <div className="mt-4">
                            <label className="block text-14">Email:</label>
                            <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="email" value={data.email} name="email" onChange={handleChange} />
                            {dataValidate.email && <span className="text-12 text-red">Invalid Email address</span>}
                        </div>
                        <div className="mt-4">
                            <label className="block text-14">Mobile:</label>
                            <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="tel" value={data.phone} name="phone" onChange={handleChange} />
                            {dataValidate.phone && <span className="text-12 text-red">Invalid Phone address</span>}
                        </div>
                        <div className="mt-4">
                            <label className="block text-14">Subject:</label>
                            <input className="h-11 outline-none black-border rounded-7 px-4 w-full mt-1" type="text" value={data.subject} name="subject" onChange={handleChange} />
                            {dataValidate.subject && <span className="text-12 text-red">Last Name cannot be more than 50 characters</span>}
                        </div>
                        <div className="mt-4">
                            <label className="block text-14">Your Message:</label>
                            <textarea className="outline-none black-border rounded-7 px-4 w-full mt-1 h-28" name="message" value={data.message} onChange={handleChange} />
                            {dataValidate.message && <span className="text-12 text-red">Message cannot be more than 300 characters</span>}
                        </div>
                        <div className="flex mt-6 justify-center">
                            <RAMARUM_BUTTON text="Submit" size="lg" ramarum_click={submitAds} />
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </div>


    );
}

function AdvertiseWithUs(props) {
    return (
        <React.Fragment>
             <div className="px-5 sm:px-28">
            <div className="flex lg:w-2/5 justify-end mt-8 hidden lg:flex">
                <img src={groupDots} />
            </div>
            {/* <div className="px-5 sm:px-28">
                <div className="flex flex-col-reverse lg:flex-row px-6 lg:px-0">
                <div className="lg:w-1/2 text-center md:text-left self-center">
                    <h1 className="text-23 sm:text-45 font-bold">Advertise with us</h1>
                    <p className="font-medium text-14 sm:text-20 md:w-1/2">Grow your business faster</p>
                    <ul className="font-medium text-14 sm:text-20 mt-5 list-disc  px-6">
                        <li>Reach 3X more potential customers</li>
                        <li>Create your ad within minutes</li>
                        <li>Only pay when someone clicks your ad</li>
                    </ul>
                </div>
                <div className="lg:w-1/2 lg:mr-8">
                    <img className="object-cover h-full w-full" alt="Advertise" src={adv1} />
                </div>
            </div>
           </div> */}

            <div className="flex mt-8  lg:space-x-6 flex-col-reverse lg:flex-row">
                <div className="lg:w-1/2 mt-14 lg:mt-0 self-center">
                    <h2 className="font-bold lg:text-45 sm:text-40">Advertise with us</h2>
                    <p className="font-semibold text-14 sm:text-20  text-justify">Grow your business your local community</p>
                    <ul className="font-medium text-14 sm:text-20 mt-5 list-disc  px-6">
                        <li className='font-semibold'>Get marketing most leading platform</li>
                        <li className='font-semibold'>Get popularity in your locality</li>
                        <li className='font-semibold'>Get more impression of your business</li>
                    </ul>
                </div>
                <div className="lg:w-1/2 flex items-center">
                    <div className="lg:p-10 h-full w-full relative">
                        <div className="absolute twoGridImage hidden lg:block">
                            <svg width="560" height="468" viewBox="0 0 560 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="175.447" cy="175.371" r="174.926" fill="url(#paint0_radial_102_2262)" />
                                <circle opacity="0.2" cx="447.505" cy="356.321" r="111.542" fill="url(#paint1_radial_102_2262)" />
                                <defs>
                                    <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.447 0.444702) rotate(90) scale(349.853)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                    <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.505 244.779) rotate(90) scale(223.084)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                        <img className='rounded-md lg:rounded-38 bg-cover h-full w-11/12 mx-auto object-cover' src={aboutus} alt="About Us" />

                    </div>
                </div>
            </div>

            <div className="flex lg:w-9/12 justify-end mt-8 hidden lg:flex">
                <img src={groupDots} />
            </div>
            {/* <div className="px-5 sm:px-28">
                <div className="flex flex-col-reverse lg:flex-row px-6 lg:px-0">
                    <div className="lg:w-2/5 lg:mr-8">
                        <img className="object-cover h-full w-full" alt="Advertise" src={adv2} />
                    </div>
                    <div className="lg:w-3/5 text-center md:text-left self-center">
                        <h1 className="text-23 sm:text-45 font-bold ml-8">Reach more people who are ready to spend</h1>
                        <p className="font-medium text-14 sm:text-20 ml-8">Our Ads puts your business above the search results and on your competitors’ pages. This means customers are more likely to find you while searching for the services you offer.</p>
                    </div>
                </div>
            </div> */}
             <div className="flex mt-8  lg:space-x-6 flex-col-reverse lg:flex-row">
                {/* <div className="lg:w-1/2 pr-10 pl-10 mt-14 lg:mt-0 self-center">
                    <h2 className="font-bold lg:text-45 sm:text-40">Reach more people who are ready to spend</h2>
                    <p className="font-semibold text-14 sm:text-20 mt-4 sm:mt-8 text-justify">Our Ads puts your business above the search results and on your competitors’ pages. This means customers are more likely to find you while searching for the services you offer.</p>
                </div> */}
                <div className="lg:w-1/2 flex items-center lg:pl-16">
                    <div className="lg:p-10 h-full w-full relative">
                        <div className="absolute twoGridImage hidden lg:block">
                            <svg width="560" height="468" viewBox="0 0 560 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.2" cx="175.447" cy="175.371" r="174.926" fill="url(#paint0_radial_102_2262)" />
                                <circle opacity="0.2" cx="447.505" cy="356.321" r="111.542" fill="url(#paint1_radial_102_2262)" />
                                <defs>
                                    <radialGradient id="paint0_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.447 0.444702) rotate(90) scale(349.853)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                    <radialGradient id="paint1_radial_102_2262" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.505 244.779) rotate(90) scale(223.084)">
                                        <stop stopColor="#FFE6B3" />
                                        <stop offset="0.442708" stopColor="#FFD171" />
                                        <stop offset="1" stopColor="#F7AC49" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>
                        <img className='rounded-md lg:rounded-38 bg-cover h-full w-11/12 mx-auto object-cover' src={ourstory} alt="About Us" />

                    </div>
                </div>
                <div className="lg:w-1/2 mt-14 lg:mt-0 self-center">
                    <h2 className="font-bold lg:text-45 sm:text-40">Reach more people and connect with other business</h2>
                    <p className="font-semibold text-14 sm:text-20 mt-4 sm:mt-8 mb-8 text-justify">We are promoting and targeting ads and supporting your community business. It helps to grow your business. Your ads will run our both platform website and mobile application to get high value.</p>
                </div>
            </div>

            <div className="mt-20">
                <LearnHowToMarket></LearnHowToMarket>
            </div>
            <div className="mt-20">
                <AdvertiseOption></AdvertiseOption>
            </div>
            <div className="mt-20 mb-20">
                <ReadyToTryOurAds></ReadyToTryOurAds>
            </div>
            </div>
        </React.Fragment>
    );
}

export default AdvertiseWithUs;