import { GET_PRESS_NEWS, HIDE_NEWS_BUTTON, GET_MORE_PRESS_NEWS, GET_PRESS_DETAILS } from '../actions/press-action';

const initialState = {
    news: {},
    newsTableData: [],
    lastPage: false,
    newsDetails: {}
}

export default function press(state = initialState, action) {
    switch (action.type) {
        case GET_PRESS_NEWS:
            return {
                ...state,
                news: action.payload,
                newsTableData: action.payload.tableData,
                lastPage: false
            }
        case GET_MORE_PRESS_NEWS:
            return {
                ...state,
                news: action.payload,
                newsTableData: [...state.newsTableData, ...action.payload.tableData]
            }
        case GET_PRESS_DETAILS:
            return {
                ...state,
                newsDetails: action.payload
            }
        case HIDE_NEWS_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}