import React, { useState } from "react";
import * as _ from 'lodash';
import './OurPartners.css'

let keyframe = {};

function OurPartners(props) {

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth
    })

    window.onresize = _.debounce(() => {
        setDimensions({
            width: window.innerWidth
        });
    }, 100)

    const dropzoneStyle = (data, str) => {
        if ((112 * data?.length) >= dimensions.width) {
            keyframe[str] = `
                @keyframes ${str} {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(calc(-112px * A_DYNAMIC_VALUE));
                    }
                }`;
            props.style.innerHTML += keyframe[str].replace(/A_DYNAMIC_VALUE/g, data?.length);
            return {
                position: `absolute`,
                width: `${112 * data?.length * 2}px`,
                top: `0px`,
                left: `0px`,
                overflow: `visible`,
                whiteSpace: `break-spaces`,
                animation: `${str} ${(112 * data?.length) / 50}s linear infinite`
            }
        }
    };

    function showScrollAnimation(data) {
        return (112 * data?.length) >= dimensions.width;
    }

    return (
        <React.Fragment>
            <div className="mt-20">
                <h1 className="text-center font-semibold sm:font-bold text-20 md:text-35">Our Partners</h1>
            </div>
            <div className="partner-slideshow">
                <div className="flex justify-center space-x-5 sm:space-x-16 mt-5 sm:mt-10" style={dropzoneStyle(props?.partners, 'partner')}>
                    {props?.partners && props.partners.map((item, i) => (<img key={i} className="filter grayscale hover:grayscale-0 sm:h-16 sm:w-28 h-10 w-16" alt={item.company_name} src={item.documents[0].display_files.company_logo.main_url} />))}
                    {showScrollAnimation(props?.partners) && props?.partners && props.partners.map((item, i) => (<img key={i} className="filter grayscale hover:grayscale-0 sm:h-16 sm:w-28 h-10 w-16" alt={item.company_name} src={item.documents[0].display_files.company_logo.main_url} />))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default OurPartners;