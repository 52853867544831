import qs from 'qs';
import axios from 'axios';
import { JOBS, JOB_DETAILS } from '../../constants';

export const GET_JOBS = 'GET_JOBS';
export const GET_JOB_DETAILS = 'GET_JOB_DETAILS';
export const HIDE_JOBS_BUTTON = 'HIDE_JOBS_BUTTON';
export const GET_MORE_JOBS = 'GET_MORE_JOBS';

export const getJobs = (request, pageNo) => async dispatch => {
    try {
        const response = await getJobsByRequestData(request, pageNo);

        dispatch({
            type: GET_JOBS,
            payload: response.data.result.responce.Jobs,
            showLoadMore: false
        })
        if (response?.data?.result?.responce?.Jobs?.tableData?.length === 0) {
            dispatch({
                type: HIDE_JOBS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getMoreJobs = (request, pageNo) => async dispatch => {
    try {
        const response = await getJobsByRequestData(request, pageNo);
        if (response?.data?.result?.responce?.Jobs?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_JOBS,
                payload: response.data.result.responce.Jobs,
                showLoadMore: false
            })
        } else {
            dispatch({
                type: HIDE_JOBS_BUTTON,
                payload: true
            })
        }
    } catch (error) {

    }
}

const getJobsByRequestData = (request, pageNo) => {
    return axios({
        method: 'post',
        url: JOBS,
        data: qs.stringify({
            'filter[post_type]': 'Jobs',
            'filter[looking_for]': request.looking_for,
            'filter[search]': request.search,
            'filter[j_type]': request.j_type,
            'sort[address]': request.sort,
            'filter[jobindustry_id]':request.jobindustry_id,
            start: pageNo
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const getJobDetails = id => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: `${JOB_DETAILS}/${id}`,
            data: qs.stringify({
                [id]: 'Job'
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        dispatch({
            type: GET_JOB_DETAILS,
            payload: {
                posts: response.data.result.responce.Posts,
                morePosts: response.data.result.responce.morePosts
            }
        })
    } catch (error) {

    }
}