import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import SocialIconsDark from '../../components/Social-Dark'
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getRidesDetails } from '../../store/actions/ride-action'
import moment from 'moment';
import email from '../../assets/images/social/email.svg';
import call from '../../assets/images/social/call.svg';


function SponsorAdsDetailsPage(props) {
    let history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRidesDetails(id))
    }, [dispatch, id]);

    const rideDetails = useSelector((state) => {
        return state.rides.rideDetails;
    });

    return (
        <React.Fragment>
            {rideDetails && <div className="md:container mx-auto md:px-10">
                <div className="mt-6 sm:mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                    <p><Link to="/">Home</Link> &gt; {rideDetails.post_title}</p>
                </div>
                <div className="mt-6 sm:mt-9">
                    <Carousel
                        autoPlay={false}
                        infiniteLoop={true}
                        swipeable={true}
                        dynamicHeight={true}
                        showStatus={false}
                        showIndicators={false}
                        showArrows={false}>
                        {rideDetails?.documents && rideDetails.documents.map((item, i) => (<img key={i} className="w-full md:rounded max-h-425" alt="Selected Car" src={item.display_files.post_image.main_url} />))}
                    </Carousel>
                    <div className="flex">
                        <div className="md:w-2/3 px-5 md:px-0">
                            <div className="mt-0 sm:mt-8">
                                <h1 className="font-bold text-20 sm:text-31">{rideDetails.post_title}</h1>
                                <h1 className="font-bold text-20 sm:text-31">{rideDetails.address_full}</h1>
                                <div className="flex items-center space-x-4 mt-4 justify-between sm:justify-start">
                                    <RAMARUM_BUTTON
                                        className="m-auto"
                                        text={rideDetails?.sponsortype_id && rideDetails?.sponsortype_id[0].text}
                                        btnType='full'
                                        size="sm" />
                                    <p>Posted {moment(rideDetails?.created).fromNow()}</p>
                                </div>
                                <p className="font-bold text-23 sm:text-35 mt-5">${rideDetails?.your_amount && rideDetails.your_amount.toFixed(2)}</p>
                                {rideDetails?.descriptions &&
                                    <div className="mt-6">
                                        <p className="font-medium text-18 sm:text-21">Description:</p>
                                        <p>{rideDetails.descriptions}</p>
                                    </div>}
                                <div className="mt-6">
                                    <p className="font-medium text-18 sm:text-21">Contact information:</p>
                                    <div className="flex items-center space-x-4 mt-4 justify-between sm:justify-start">
                                        <img className="h-6 w-6 sm:h-8 sm:w-8" alt="Linkedin" src={email} />
                                        <p>{rideDetails.email}</p>
                                    </div>
                                    <div className="flex items-center space-x-4 mt-4 justify-between sm:justify-start">
                                        <img className="h-6 w-6 sm:h-8 sm:w-8" alt="Linkedin" src={call} />
                                        <p>{rideDetails.phone}</p>
                                    </div>
                                </div>

                                <div className="font-medium text-25 mt-11 mb-11">
                                    <SocialIconsDark title="Share This Ride On" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="hidden md:block mt-n-10">
                            <MobileAppAd />
                        </div> */}
                    </div>
                </div>
            </div>}
        </React.Fragment>
    );
};

export default SponsorAdsDetailsPage;