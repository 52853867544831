import React from "react";
import moment from 'moment';

function JobCard(props) {

    return (
        // className="bg-white w-40 h-56 min-w-40 sm:h-96 sm:w-64 sm:min-w-64 shadow rounded mx-auto cursor-pointer"
        <div className="bg-white rjw-card h-56 min-w-40 sm:h-96 shadow rounded mx-auto cursor-pointer">
            <div className="h-1/2">
                <img className="h-full object-cover rounded rounded-b-lg w-full" src={props.displayImage} alt="Job" />
            </div>
            <div className="h-1/2 relative">
                <div className="absolute bottom-0 w-full p-2 sm:p-4">
                    <div>
                        <p className="font-medium text-12 sm:text-18">{props.title}</p>
                        <p className="font-medium text-12 sm:text-18 max-line3 ">{props.text}</p>
                    </div>
                    <p className="font-normal sm:font-medium text-12 sm:text-18 pt-2.5">{props.type}</p>
                    <div className="flex justify-between items-baseline">
                        <p className="font-bold text-12 sm:text-20 pt-2.5">${props.amount && props.amount.toFixed(2)}</p>
                        <p className="text-9 sm:text-13">{moment(props.timestamp).fromNow()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobCard;