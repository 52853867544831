import React from "react";
import facebook from '../assets/images/social/facebook-dark.svg';
import twitter from '../assets/images/social/twitter-dark.svg';
import linkedin from '../assets/images/social/linkedin-dark.svg';
import instagram from '../assets/images/social/instagram-dark.svg';
import youtube from '../assets/images/social/youtube-dark.svg';

function SocialIconsDark(props) {
    return (
        <React.Fragment>
            <p className="font-medium text-25 mt-11">{props.title}:</p>
            <div className="flex space-x-5 cursor-pointer mt-5">
                {/* <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ramarum.com%2F&amp;src=sdkpreparse" target="_blank">
                    <img className="h-10 w-10 sm:h-12 sm:w-12 fb-share-button" alt="Facebook" data-href="https://www.ramarum.com" src={facebook} />
                </a>
                <a href="http://twitter.com/share?url=https://www.ramarum.com" target="_blank">
                    <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Twitter" src={twitter} />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=https://www.ramarum.com" target="_blank">
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Linkedin" src={linkedin} />
                </a>
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Linkedin" src={linkedin} />
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Instagram" src={instagram} />
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Youtube" src={youtube} /> */}

                <a href="https://www.facebook.com/ramarumofficial/" target="_blank">
                    <img className="h-10 w-10 sm:h-12 sm:w-12 fb-share-button" alt="Facebook" data-href="https://www.ramarum.com" src={facebook} />
                </a>
                <a href="https://twitter.com/ramarumofficial" target="_blank">
                    <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Twitter" src={twitter} />
                </a>
                <a href="https://www.linkedin.com/company/ramarumofficial" target="_blank">
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Linkedin" src={linkedin} />
                </a>
                <a href="https://www.instagram.com/ramarumofficial/" target="_blank">
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Instagram" src={instagram} />
                </a>
                <a href="https://www.youtube.com/channel/UCMcdnOiP7oDgvAEHrkn-esQ" target="_blank">
                <img className="h-10 w-10 sm:h-12 sm:w-12" alt="Youtube" src={youtube} />
                </a>
              
                
            </div>
        </React.Fragment>
    );
};

export default SocialIconsDark;