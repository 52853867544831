import { GET_JOBS, GET_JOB_DETAILS, GET_MORE_JOBS, HIDE_JOBS_BUTTON } from '../actions/job-action';

const initialState = {
    jobs: [],
    jobsTableData: [],
    jobDetails: {},
    morePosts: [],
    lastPage: false,
}

export default function job(state = initialState, action) {
    switch (action.type) {
        case GET_JOBS:
            return {
                ...state,
                jobs: action.payload,
                jobsTableData: action.payload.tableData,
                lastPage: action.showLoadMore
            }
        case GET_MORE_JOBS:
            return {
                ...state,
                jobs: action.payload,
                jobsTableData: [...state.jobsTableData, ...action.payload.tableData],
                lastPage: action.showLoadMore
            }
        case GET_JOB_DETAILS:
            return {
                ...state,
                jobDetails: action.payload.posts,
                morePosts: action.payload.morePosts
            }
        case HIDE_JOBS_BUTTON:
            return {
                ...state,
                lastPage: action.payload
            }
        default:
            return state;
    }
}