import React, { useEffect } from 'react';
import rideBg from '../../assets/images/rides-bg.svg';
import rideMobileBg from '../../assets/images/rides-mobile-bg.jpg';
import rideMobilePath from '../../assets/images/rides-mobile-path.svg';
import SaleCard from '../../components/Sale/Sale-Card';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import GetStarted from '../../components/GetStarted';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getAdsData, getMoreAds } from '../../store/actions/ads-action';

function AdsPage(props) {
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdsData(0));
    }, [dispatch]);

    const ads = useSelector((state) => {
        return state.ads.adsTableData;
    });

    const adsObj = useSelector((state) => {
        return state.ads.ads;
    });

    const hideLoadMore = useSelector((state) => {
        return state.ads.lastPage;
    });

    const adsClick = item => {
        // history.push(`/ads/${item.id}`);
    }

    const loadMore = () => {
        dispatch(getMoreAds(adsObj.nextPageStart))
    }

    return (
        <div>
            <div className="pt-12 relative">
                <div className="">
                    <img className="hidden lg:block" src={rideBg} width="100%" height="100%" alt="" />
                    <img className="block lg:hidden" src={rideMobileBg} width="100%" height="100%" alt="" />
                    <div className="lg:absolute lg:w-1/3 left-24 top-24 px-7">
                        <h1 className="text-19 sm:text-35 xl:text-45 font-bold">We Will Find Best Job For You</h1>
                        <p className="text-12 sm:text-18 xl:text-20 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                    <img className="block lg:hidden" src={rideMobilePath} width="100%" height="100%" alt="" />
                </div>
            </div>
            <div className="mt-20 container m-auto">
                <div>
                    <div className="text-center">
                        <h1 className="text-19 sm:text-45 font-bold">Popular Ads Near You</h1>
                        <p className="text-12 sm:text-17 mt-1.5">Let's make this your least expensive journey ever.</p>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-4 mt-10">
                            {ads && ads.map((item, i) => (
                                <div key={i} onClick={() => adsClick(item)}>
                                    <SaleCard
                                        displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                        // text={`${item?.saddress.slice(0, 20)}${item?.saddress.length > 20 && '...'} to ${item?.daddress.slice(0, 20)}${item?.daddress.length > 20 && '...'}`}
                                        text={`${item?.address_full.slice(0, 20)}${item?.address_full.length > 20 && '...'}`}
                                        amount={item?.your_amount}
                                        btnType="full"
                                        btnText={item?.sponsortype_id && item?.sponsortype_id[0].text}
                                        timestamp={item?.created}
                                    />
                                </div>
                            ))}
                        </div>
                        {!hideLoadMore && <div className="mt-14 hidden sm:flex justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}
                        {!hideLoadMore && <div className="mt-14 flex sm:hidden justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="md" ramarum_click={loadMore} />
                        </div>}
                    </div>
                </div>
                <div className="mt-20 sm:mt-60">
                    <GetStarted />
                </div>
            </div>
        </div>
    );
};

export default AdsPage;