import axios from 'axios';
import { FAQS } from '../../constants';

export const GET_FAQS = 'GET_FAQS';

export const getFaqs = () => async dispatch => {
    try {
        const response = await axios.get(FAQS);
        dispatch({
            type: GET_FAQS,
            payload: response.data.result.responce.Faqs
        })
    } catch (error) {

    }
}