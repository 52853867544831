import qs from 'qs';
import axios from 'axios';
import { BLOGS, BLOG_DETAILS } from '../../constants';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_DETAILS = 'GET_BLOGS_DETAILS';
export const HIDE_BLOGS_BUTTON = 'HIDE_BLOGS_BUTTON';
export const GET_MORE_BLOGS = 'GET_MORE_BLOGS';

export const getBlogs = (request, category, tags, pageNo) => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: BLOGS,
            data: qs.stringify({
                'filter[search]': request.search,
                'filter[category_id]': request.category_id,
                'filter[blog_tag]': request.blog_tag,
                'start': pageNo
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        dispatch({
            type: GET_BLOGS,
            payload: {
                blogs: response.data.result.responce.Blogs,
                blogTags: response.data.result.responce.getBlogTags,
                blogCategories: response.data.result.responce.getBlogCategories,
                showLoadMore: false
            },
        })

        if (response?.data?.result?.responce?.Blogs?.tableData?.length === 0) {
            dispatch({
                type: HIDE_BLOGS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getMoreBlogs = (request, pageNo) => async dispatch => {
    try {
        const response = await axios({
            method: 'post',
            url: BLOGS,
            data: qs.stringify({
                'filter[search]': request.search,
                'filter[category_id]': '',
                'filter[blog_tag]': '',
                'start': pageNo
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response?.data?.result?.responce?.Blogs?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_BLOGS,
                payload: response.data.result.responce.Blogs
            })
        } else {
            dispatch({
                type: HIDE_BLOGS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getBlogDetails = id => async dispatch => {
    try {
        const response = await axios.get(`${BLOG_DETAILS}/${id}`);
        dispatch({
            type: GET_BLOGS_DETAILS,
            payload: response.data.result.responce.Blogs
        })
    } catch (error) {

    }
}