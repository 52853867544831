import MOBILE_DOWNLOAD_LINK from './Download-Links';
// import dualMobile from '../assets/images/mobile/dualmobiles.svg';
import dualMobile from '../assets/images/mobile/appGroup.png';

function GetStarted(props) {
    return (
        <div className="flex flex-col-reverse lg:flex-row px-6 lg:px-0">
            <div className="lg:w-1/2">
                {/* <img className="object-cover lg:h-96 sm:h-full w-full" alt="Mobile" src={dualMobile} /> */}
                 <img alt="Mobile" src={dualMobile} />
            </div>
            <div className="lg:w-1/2 text-center md:text-left pt-16">
                <h1 className="hw-title font-bold">Ready To Get Started?</h1>
                <p className="font-medium hw-disc mt-5 md:w-8/12">Ramarum mobile application makes your life easier than ever before. Download a mobile application today.</p>
                <div className="flex space-x-5 hw-mt-user mb-5 justify-center lg:justify-start">
                    <MOBILE_DOWNLOAD_LINK />
                </div>
            </div>
        </div>
    );
};

export default GetStarted;