import React from "react";
import googlePlay from '../assets/images/mobile/googlePay.svg';
import appStore from '../assets/images/mobile/appStore.svg';
import googlePlayWhite from '../assets/images/mobile/googlePlayWhite.svg';
import appStoreWhite from '../assets/images/mobile/appStoreWhite.svg';

function MOBILE_DOWNLOAD_LINK(props) {
    return (
        <React.Fragment>
            <img className="downloadLinks cursor-pointer" alt="Google Play" src={props.image === "WHITE" ? googlePlayWhite: googlePlay} />
            <img className="downloadLinks cursor-pointer" alt="App Store" src={props.image === "WHITE" ? appStoreWhite: appStore} />
        </React.Fragment>
    );
};

export default MOBILE_DOWNLOAD_LINK;