import qs from 'qs';
import axios from 'axios';
import { INVESTORS, INVESTOR_DETAILS } from '../../constants';

export const GET_INVESTORS = 'GET_INVESTORS';
export const GET_INVESTORS_DETAILS = 'GET_INVESTORS_DETAILS';
export const HIDE_INVESTORS_BUTTON = 'HIDE_INVESTORS_BUTTON';
export const GET_MORE_INVESTORS = 'GET_MORE_INVESTORS';

export const getInvestors = pageNo => async dispatch => {
    try {
        const response = await getInvestorsByRequestData(pageNo);

        dispatch({
            type: GET_INVESTORS,
            payload: {
                investors: response.data.result.responce.Investors,
                showLoadMore: false
            },
        })

        if (response?.data?.result?.responce?.Investors?.tableData?.length === 0) {
            dispatch({
                type: HIDE_INVESTORS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

export const getMoreInvestors = pageNo => async dispatch => {
    try {
        const response = await getInvestorsByRequestData(pageNo);

        if (response?.data?.result?.responce?.Investors?.tableData?.length !== 0) {
            dispatch({
                type: GET_MORE_INVESTORS,
                payload: response.data.result.responce.Investors
            })
        } else {
            dispatch({
                type: HIDE_INVESTORS_BUTTON,
                payload: true
            })
        }
    } catch (error) { }
}

const getInvestorsByRequestData = pageNo => {
    return axios({
        method: 'post',
        url: INVESTORS,
        data: qs.stringify({
            start: pageNo
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const getInvestorsDetails = id => async dispatch => {
    try {
        const response = await axios.get(`${INVESTOR_DETAILS}/${id}`);
        dispatch({
            type: GET_INVESTORS_DETAILS,
            payload: response.data.result.responce.Investors
        })
    } catch (error) {

    }
}