import React, { useEffect, useState, useCallback } from 'react';
import rideBg from '../../assets/images/rides-bg.svg';
import rideMobileBg from '../../assets/images/rides-mobile-bg.jpg';
import rideMobilePath from '../../assets/images/rides-mobile-path.svg';
import gasStation from '../../assets/images/jobCatagories/gasstation.svg';
import JobCard from '../../components/Job/Job-Card';
import RAMARUM_BUTTON from '../../components/Ramarum-Button';
import GetStarted from '../../components/GetStarted';
import DescriptionCard from '../../components/Description/Description-Card';
import InputSearch from '../../components/Input-Search';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getJobs, getMoreJobs } from '../../store/actions/job-action';
import * as _ from 'lodash';
import './Jobs.css';
import { POST_MASTER } from '../../constants';
import bakery from '../../assets/images/jobCatagories/Bakery.png';
import cleaning from '../../assets/images/jobCatagories/cleaning.png';
import convenienceStore from '../../assets/images/jobCatagories/Convenience-store.png';
import electronics from '../../assets/images/jobCatagories/Electronics.png';
import fastFood from '../../assets/images/jobCatagories/Fast-food.png';
import FoodFranchiseChain from '../../assets/images/jobCatagories/FoodFranchiseChain.png';
import foodTruck from '../../assets/images/jobCatagories/food-truck.png';
import IT from '../../assets/images/jobCatagories/it.png';
import miscellaneous from '../../assets/images/jobCatagories/Miscellaneous.png';
import products from '../../assets/images/jobCatagories/products.png';

function JobsPage(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    // let  industyMaster=[];
    const defaultValues = {
        industyMaster: []
    };
    const [dataIndustryMaster, setIndustryMaster] = useState({ ...defaultValues });

    const [data, setData] = useState({
        search: "",
        range: "",
        sort: "asc",
        looking_for: "JS",
        j_type: "F",
        jobindustry_id:"",
        // industyMaster:[]
       
    });

    const postJobData = useCallback(
        _.debounce(data => {
            dispatch(getJobs(data, 0))
        }, 1000),
        []
    );

    useEffect(() => {
        fetch(POST_MASTER)
          .then(res => res.json())
          .then(data => {
            const post_master=data.result.responce.getPostMaster.Jobs;
            if (post_master && post_master.search) {
            setIndustryMaster({
                ...dataIndustryMaster,
                industyMaster:post_master.search.jobindustry.value
            });
        }
          });
      }, []);


    useEffect(() => {
        postJobData(data);
    }, [dispatch, data]);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const jobs = useSelector((state) => {
        return state.jobs.jobsTableData;
    });

    const jobsObj = useSelector((state) => {
        return state.jobs.jobs;
    });

    const hideLoadMore = useSelector((state) => {
        return state.jobs.lastPage;
    });

    const jobClick = item => {
        history.push(`/jobs/${item.id}`);
    }

    const loadMore = () => {
        dispatch(getMoreJobs(data, jobsObj.nextPageStart))
    }

    return (
        <div>
            <div className="pt-12 relative">
                <div className="">
                    <img className="hidden lg:block" src={rideBg} width="100%" height="100%" alt="" />
                    <img className="block lg:hidden" src={rideMobileBg} width="100%" height="100%" alt="" />
                    <div className="lg:absolute lg:w-1/3 left-24 top-24 px-7">
                        <h1 className="hw-title font-bold">Post & Get Job nearest you</h1>
                        <p className="hw-disc mt-5 text-justify">We provide the ultimate solution for those who want to connect business users with candidates who are looking for jobs with affordable prices.</p>
                    </div>
                    <img className="block lg:hidden" src={rideMobilePath} width="100%" height="100%" alt="" />
                </div>
            </div>
            <div className="mt-20 container m-auto">
                <div>
                    <div className="text-center">
                        <h1 className="text-19 sm:text-45 font-bold">Popular Jobs Near You</h1>
                        <p className="text-12 sm:text-17 mt-1.5">Let's make this your least expensive journey ever.</p>
                    </div>
                    <div>
                        <div className="grid grid-cols-1 xl:grid-cols-4 mt-10 px-5">
                            <div className='mt-10'>
                                <InputSearch placeholder="Search Job With Location, Title" onChange={handleChange} />
                            </div>
                            <div className="flex items-center mt-10 justify-around">
                                <div className="flex items-center">
                                {/* <label className="text-18 font-light">Job Industry:</label> */}
                                    <select className="outline-none font-semibold text-16" name="jobindustry_id"  onChange={handleChange} >
                                        <option value="">Select Job Industry</option>
                                        {dataIndustryMaster.industyMaster && dataIndustryMaster.industyMaster.map((item, i) => (
                                             <option value={item.id}>{item.name}</option>
                                              ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center mt-10 justify-around">
                                <div className="flex items-center">
                                    <select className="outline-none font-semibold text-16" name="looking_for" defaultValue="JS" onChange={handleChange} >
                                        <option value="JS">Looking For A Job</option>
                                        <option value="JP">Offering A Job</option>
                                        <option value="">Both</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center justify-around">
                                <div className="flex items-center mt-10">
                                    {/* <label className="text-18 font-light">Type:</label> */}
                                    <select className="outline-none font-semibold text-16" defaultValue="F" name="j_type" onChange={handleChange}>
                                        <option valaue="F">Full Time</option>
                                        <option valaue="P">Part Time</option>
                                        <option valaue="R">Remote</option>
                                    </select>
                                </div>
                                <div className="flex items-center mt-10">
                                    {/* <label className="text-18 font-light">Sort By:</label> */}
                                    <select className="outline-none font-semibold text-16" defaultValue="asc" name="sort" onChange={handleChange}>
                                        <option value="asc">Sort By: A-Z</option>
                                        <option value="desc">Sort By: Z-A</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-11">
                            {jobs && jobs.map((item, i) => (
                                <div key={i} onClick={() => jobClick(item)}>
                                    <JobCard
                                        displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                        title={item?.businesstype_id && item?.businesstype_id[0].text}
                                        // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                        text={item.address_full}
                                        type={item?.amount_type && item?.amount_type[0].text}
                                        amount={item?.your_amount}
                                        timestamp={item?.created}
                                    />
                                </div>
                            ))}
                        </div>
                        {!hideLoadMore && <div className="mt-14 hidden sm:flex justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="lg" ramarum_click={loadMore} />
                        </div>}
                        {!hideLoadMore && <div className="mt-14 flex sm:hidden justify-center">
                            <RAMARUM_BUTTON className="m-auto" text="Load More" size="md" ramarum_click={loadMore} />
                        </div>}
                    </div>
                </div>
                <div className="mt-20 sm:mt-40">
                    <DescriptionCard title1="Easiest way to find job near you"
                    subTitle1="There is a quick setup on a mobile application, start finding jobs.."
                    dataList1={["Free registration","Enough of jobs near you","No payment required","Direct chat with employer"]} 
                    title2="Easiest way to post  jobs near you"
                    subTitle2="There is a quick setup on a mobile application, start posting jobs."
                    dataList2={["Free registration","Enough job partner near you","Employer gateway","Direct chat with candidates"]}/>
                </div>
                <div>
                    <div className="text-center mt-20 sm:mt-40">
                        <h1 className="text-19 sm:text-35 font-bold">Categories</h1>
                    </div>
                    <div className="grid justify-items-center grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 mt-20">
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={bakery} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Bakery</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={cleaning} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Cleaning</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={convenienceStore} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Convenience Store</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={electronics} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Electronics</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={fastFood} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Fast Food</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={FoodFranchiseChain} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Food Franchise Chain</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={foodTruck} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Food Truck</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={IT} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">IT</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={products} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">products</p>
                        </div>
                        <div className="w-20 sm:w-44">
                            <img className="h-20 w-20 sm:h-44 sm:w-44" src={miscellaneous} alt="Gas Station" />
                            <p className="font-semibold text-center text-13 sm:text-20">Miscellaneous</p>
                        </div>
                    </div>
                </div>
                <div className="mt-20 sm:mt-60">
                    <GetStarted />
                </div>
            </div>
        </div>
    );
};

export default JobsPage;