import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import logo from '../../assets/images/logo.svg';
import logo from '../../assets/images/logo.png';
import './Navbar.css';
import { useHistory } from "react-router-dom";

function NavBar(props) {

    const [showMenu, setShowMenu] = useState(false);
    let history = useHistory();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const gotoHome = () => {
        history.push(`/`);
    }

    return (
        <React.Fragment>
            <nav className="shadow">
                <div className="flex justify-center lg:justify-between">
                    <div className="ml-0 lg:ml-24">
                        <div className="flex items-center">
                            <svg onClick={toggleMenu} className="absolute left-5 block lg:hidden" width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.512695" y="0.782715" width="22.5464" height="2.50515" rx="1.25258" fill="#464F62" />
                                <rect x="0.512695" y="8.2981" width="22.5464" height="2.50515" rx="1.25258" fill="#464F62" />
                                <rect x="0.512695" y="15.7827" width="22.5464" height="2.50515" rx="1.25258" fill="#464F62" />
                            </svg>
                            <img className="block xl:hidden h-9 w-32 my-3 cursor-pointer" src={logo} alt="Logo" onClick={gotoHome} />
                        </div>
                        <img className="hidden xl:block h-9 h-55 w-200 my-3 cursor-pointer" src={logo} alt="Logo" onClick={gotoHome} />
                    </div>
                    <div className="hidden lg:flex items-center space-x-16 mr-16">
                        <NavLink exact to="/" className="text-18 font-semibold" activeClassName="selectedPage">Home</NavLink>
                        <NavLink exact to="/rides" className="text-18 font-semibold" activeClassName="selectedPage">Rides</NavLink>
                        <NavLink exact to="/jobs" className="text-18 font-semibold" activeClassName="selectedPage">Jobs</NavLink>
                        <NavLink exact to="/about" className="text-18 font-semibold" activeClassName="selectedPage">About us</NavLink>
                        <NavLink exact to="/blog" className="text-18 font-semibold" activeClassName="selectedPage">Blog</NavLink>
                        <NavLink exact to="/contactus" className="text-18 font-semibold" activeClassName="selectedPage">Contact us</NavLink>
                    </div>
                </div>
            </nav>
            <div className={`absolute z-50 bg-white w-full h-full items-center lg:hidden ${showMenu ? 'flex' : 'hidden'}`}>
                <div className="text-center w-full grid grid-cols-1">
                    <NavLink exact to="/" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>Home</NavLink>
                    <NavLink exact to="/rides" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>Rides</NavLink>
                    <NavLink exact to="/jobs" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>Jobs</NavLink>
                    <NavLink exact to="/about" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>About us</NavLink>
                    <NavLink exact to="/blog" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>Blog</NavLink>
                    <NavLink exact to="/contactus" className="text-18 font-semibold py-5" activeClassName="selectedPage" onClick={toggleMenu}>Contact us</NavLink>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NavBar;