import { GET_STATASTICS } from '../actions/howitswork-action';

const initialState = {
    statastics: {},
}

export default function ride(state = initialState, action) {
    switch (action.type) {
        case GET_STATASTICS:
            return {
                ...state,
                statastics: action.payload,
            }
        default:
            return state;
    }
}   