import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JobCard from '../../components/Job/Job-Card';
import MobileAppAd from '../../components/AppDetails/MobileApp-Ad';
import SocialIconsDark from '../../components/Social-Dark';
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getJobDetails } from '../../store/actions/job-action';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import SocialIconsDarkShare from '../../components/Social-Dark-share';

function JobDetails(props) {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        dispatch(getJobDetails(id))
    }, [dispatch, id]);

    const jobDetails = useSelector((state) => {
        console.log(state.jobs.jobDetails);
        return state.jobs.jobDetails;
    });

    const morePosts = useSelector((state) => {
        return state.jobs.morePosts;
    });

    function jobClick(item) {
        history.push(`/jobs/${item.id}`);
    }

    return (
        <div className="container mx-auto md:px-10">
            <div className="mt-10 opacity-50 text-17 sm:text-20 px-5 md:px-0">
                <p><Link to="/">Home</Link> &gt; <Link to="/jobs">Jobs</Link> &gt; {jobDetails?.businesstype_id && jobDetails?.businesstype_id[0].text}</p>
            </div>
            <div className="mt-6 sm:mt-9">
                <Carousel
                    autoPlay={false}
                    infiniteLoop={true}
                    swipeable={true}
                    dynamicHeight={true}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={false}>
                    {jobDetails?.documents && jobDetails.documents.map((item) => (<img className="w-full md:rounded max-h-425" alt="Selected Car" src={item.display_files.post_image.main_url} />))}
                </Carousel>
                <div className="flex">
                    <div className="md:w-2/3 px-5 md:px-0">
                        <div className="mt-0 sm:mt-8">
                            <h1 className="font-bold text-20 sm:text-31">{jobDetails?.businesstype_id && jobDetails?.businesstype_id[0].text}</h1>
                            <p className="opacity-50">Posted {moment(jobDetails?.created).fromNow()}</p>
                            <p>{jobDetails?.address_full}</p>
                            <p>{jobDetails?.amount_type && jobDetails?.amount_type[0].text}</p>
                            <p className="font-bold text-23 sm:text-35 mt-5">${jobDetails?.your_amount && jobDetails.your_amount.toFixed(2)}</p>
                            <div className="sm:mt-6 mt-4">
                                <p className="font-medium text-18 sm:text-21">Description:</p>
                                <ul className="text-18 sm:text-21 mt-4">
                                    <li className="mt-3">Non-Smoking</li>
                                    <li className="mt-3">Pet Friendly</li>
                                </ul>
                            </div>
                            <p className="text-18 sm:text-28 font-semibold mt-7">Ready To Ride ? <span className="text-darkYellow">Download Our App Today.</span></p>
                            <div className="font-medium text-25 mt-11">
                                <SocialIconsDarkShare title="Share This Job On" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block mt-n-10">
                        <MobileAppAd />
                    </div>
                </div>
            </div>
            <div className='px-5 md:px-0'>
                <h2 className="font-bold text-35 mt-20">More Jobs</h2>
                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 my-11">
                    {morePosts && morePosts.map((item, i) => (
                        <div key={i} onClick={() => jobClick(item)}>
                            <JobCard
                                displayImage={item?.documents[0]?.display_files?.post_image?.square_url}
                                title={item?.businesstype_id && item?.businesstype_id[0].text}
                                // text={`${item.address_full.slice(0, 20)}${item.address_full.length > 20 && '...'}`}
                                text={item.address_full}
                                type={item?.amount_type && item?.amount_type[0].text}
                                amount={item?.your_amount}
                                timestamp={item?.created}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default JobDetails;